import { SyntheticEvent, useContext } from 'react';
import styles from './CloseButton.module.scss';
import StyledIcon from '@/components/base/StyledIcon';
import Close from '@/public/puma/images/icons/Close.svg';
import LanguageLocalizer from '@/utils/language-localization';

type CloseButtonProps = {
  handleClick?: (_e: SyntheticEvent) => void
  className?: string
};

export default function CloseButton({ handleClick, className }: CloseButtonProps) {
  const { __t } = new LanguageLocalizer( useContext, 'components/base/CloseButton' );

  return (
    <button
      className={`${className || ''} ${styles.button}`}
      onClick={handleClick}>
      <span className='sr-only'>
        { __t('dismiss')}
      </span>
      <StyledIcon
        icon={ Close }
        classList={styles.icon}
      />
    </button>
  );
}
