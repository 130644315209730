import Logger from './base';
import { isSentryEnabled, sendLogMessageToSentry } from '@/utils/sentry';

const isUnitTestSuite = process.env.NODE_ENV === 'test';
const isBuilding = typeof process !== 'undefined' && process.env.npm_lifecycle_script === 'next build';
const isRunningInBrowser = typeof window !== 'undefined';

let welcomed = false;

class FullLogger extends Logger {
  constructor(
    {
      caller = 'unknown',
      levels,
    }: {
      caller: string,
      levels?: Array<string> | string
    },
  ) {
    super({ caller, levels });

    if (!isRunningInBrowser && !isUnitTestSuite && !isBuilding && !welcomed) {
      this.welcomeMessage();
      welcomed = true;
    }

    if (isSentryEnabled()) {
      const logOriginalError = this.error;

      this.error = (...args: unknown[]) => {
        logOriginalError(...args);
        sendLogMessageToSentry(caller, 'error', new Date(), args);
      };

      const logOriginalWarning = this.warn;

      this.warn = (...args: unknown[]) => {
        logOriginalWarning(...args);
        sendLogMessageToSentry(caller, 'warn', new Date(), args);
      };
    }
  }
}

export default FullLogger;
