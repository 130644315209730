import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

let itemsLoading = 0;
let loaderIsShowing = false;

const start = () => {
  itemsLoading++;
  if (!loaderIsShowing) {
    loaderIsShowing = true;
    NProgress.start();
  }
};

const complete = () => {
  itemsLoading--;
  if (itemsLoading <= 0) {
    itemsLoading = 0;
    loaderIsShowing = false;
    NProgress.done();
  }
};

const handleRouterEvents = () => {
  Router.events.on('routeChangeStart', start);
  Router.events.on('routeChangeComplete', complete);
  Router.events.on('routeChangeError', () => complete);
  NProgress.configure({ showSpinner: false });
};

const progressBar = {
  start,
  complete,
  handleRouterEvents,
};

export default progressBar;
