import { getEnvData } from '../../utils/env';

const { deviceSizes } = getEnvData().nextImageConfig;

// Let's cap requests at the largest supported device width
// then if the cap gets applied, we'll do the math to preserve the requested aspect ratio
const dimensionCap = deviceSizes[deviceSizes.length - 1];

// Based on: <https://stackoverflow.com/a/14731922>
const calculateAspectRatioFit = (srcWidth: number, srcHeight: number) => {
  const maxWidth = dimensionCap;
  const maxHeight = dimensionCap;
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const isValidDimension = (dimension: number) => {
  return !isNaN(dimension) && dimension > 0 && dimension % 1 === 0;
};

const ensureWidthIsSupported = (width: number) => {
  if (!deviceSizes.includes(width)) {
    const nextLargestWidth = deviceSizes
      .sort((a, b) => a - b)
      .find((deviceWidth) => deviceWidth >= width);

    if (nextLargestWidth) {
      width = nextLargestWidth;
    }
  }
  return width;
};

const getOptimizedImageUrl = (originalUrl: string, width: number, height?: number, quality: number = 75): string => {
  if (originalUrl.match(/\.svg$/)) {
    return originalUrl;
  }

  if ( !isValidDimension(width) || ( height && !isValidDimension(height) ) ) {
    // eslint-disable-next-line no-console
    console.error('Error requesting image with getOptimizedImageUrl():', { originalUrl, width, height });
    throw new Error('Image dimensions must be positive integers');
  }

  width = ensureWidthIsSupported(width);

  if (width > dimensionCap || (height || -1) > dimensionCap) {
    const maxSupported = calculateAspectRatioFit( width, height || width );

    width = Math.ceil(maxSupported.width);

    if (height) {
      height = maxSupported.height;
    }
  }

  if (height) {
    height = Math.ceil(height);
  }

  const url = `/_next/image?url=${encodeURIComponent(originalUrl)}&w=${width}&q=${quality}`;
  return height ? `${url}&h=${height}` : url;
};

const adjustIndention = (str: string, indent: number) => {
  return str.split('\n').map((line) => {
    if (line === '') {
      // empty lines should not need indention
      return line;
    }

    if (indent < 0) {
      const adjusted = line.replace(RegExp(`^ {${-indent}}`), '');
      if (adjusted !== line) {
        // If the line wasn't adjusted, it means it didn't have the expected indentation
        return adjusted;
      }
      // So we'll just return it without *any* indentation
      return line.replace(RegExp('^\ +'), '');
    } else {
      return ' '.repeat(indent) + line;
    }
  }).join('\n');
};

const getImageSet = (originalUrl: string, imageWidth: number, selector: string, propertyName: string, quality = 75) => {
  const urls = `
      url('${getOptimizedImageUrl(originalUrl, Math.ceil(imageWidth), undefined, quality)}') 1x,
      url('${getOptimizedImageUrl(originalUrl, Math.ceil(imageWidth * 2), undefined, quality)}') 2x
    `;

  return `${selector} {
    ${propertyName}: image-set(${urls});
    ${propertyName}: -webkit-image-set(${urls});
  }`;
};

const deepClone = (obj: object) => JSON.parse(JSON.stringify(obj));

const getOptimizedImageSetCss = (originalUrl: string, propertyName = 'background-image', selector = ':root', scaleFactor = 1, quality = 75): string => {
  const sizesToOutput = deepClone(deviceSizes);
  const minSize = (sizesToOutput.shift() as number) * scaleFactor;
  const defaultSet = getImageSet(originalUrl, minSize, selector, propertyName, quality);
  const uniqueSets: Array<{ imgSet: string, deviceWidth: number }> = [];

  for (const deviceWidth of sizesToOutput) {
    const imageWidth = Math.ceil(deviceWidth * scaleFactor);
    const cssString = getImageSet(originalUrl, imageWidth as number, selector, propertyName, quality);

    if (!uniqueSets.find((imgSet) => imgSet.imgSet === cssString)) {
      uniqueSets.push({ imgSet: cssString, deviceWidth });
    }
  }

  return [
    adjustIndention( defaultSet, -2 ),

    ...uniqueSets.map(({ imgSet, deviceWidth }) => {
      const withMediaQuery = `
        @media only screen and (min-width: ${deviceWidth}px) {
          ${adjustIndention(imgSet, 8).trim()}
        }`;

      return adjustIndention(withMediaQuery, -8);
    }),

    '',
  ].join('\n');
};

export {
  adjustIndention,
  calculateAspectRatioFit,
  getOptimizedImageSetCss,
  getOptimizedImageUrl,
};
