import { deepValue } from '@/utils/deep-value';
import { PerformanceCheckResultValue, PerformanceCheckTest } from '../types';

const getConnection = () => {
  const connection = (
    deepValue(navigator, 'connection') ??
    deepValue(navigator, 'mozConnection') ??
    deepValue(navigator, 'webkitConnection')
  );

  if (!connection) {
    throw new Error('Browser does not support NetworkInformation API');
  }
  return connection;
};

const networkSpeedTest: PerformanceCheckTest = {
  name: 'Network Speed',
  methods: [
    {
      description: 'NetworkInformation API',
      method: async () => {
        const connection = getConnection();
        const { effectiveType } = connection;

        let reason = '';
        let value;

        if (connection?.saveData) {
          reason = 'NetworkInformation.saveData = true';
          value = PerformanceCheckResultValue.LEVEL_ONE;
        } else if (effectiveType) {
          reason = `NetworkInformation.effectiveType = ${effectiveType}`;

          if ([ 'slow-2g', '2g' ].includes(effectiveType)) {
            value = PerformanceCheckResultValue.LEVEL_ONE;
          } else if (effectiveType === '3g') {
            value = PerformanceCheckResultValue.LEVEL_TWO;
          } else if (effectiveType === '4g') {
            value = PerformanceCheckResultValue.LEVEL_THREE;
          }
        }
        return {
          value,
          reason,
        };
      },
    },
    {
      description: 'Fallback',
      method: async () => {
        return {
          reason: 'No reliable network speed test supported: don\'t scale back UX',
          value: PerformanceCheckResultValue.LEVEL_THREE,
        };
      },
    },
  ],
};

export {
  networkSpeedTest,
};
