import React, { ReactNode, useRef } from 'react';
import ClickAnimation from '@/components/base/ClickAnimationWrapper';
import Modal from '@/components/base/Modal';
import BridgeModal from '@/components/modules/BridgeModal';
import BridgeModalSponsor from '@/components/modules/BridgeModalSponsor';
import styles from './BridgeLink.module.scss';

interface Props {
  clickAnimation?: boolean,
  href: string,
  title: string,
  modalImage?: string,
  imageAltText?: string
  modalHeader?: string,
  modalBody?: string,
  linkLogo?: string,
  children: ReactNode,
  className?: string,
}

const BridgeLink: React.FC<Props> = ({
  clickAnimation = false,
  href,
  title,
  modalImage,
  imageAltText,
  modalHeader,
  modalBody,
  linkLogo,
  children,
  className,
}) => {
  const ref = useRef<HTMLButtonElement|null>(null);
  const url = new URL(href);
  const hostname = url.hostname;
  const pathname = url.pathname;
  const buttonClass = `${styles.button} ${className || ''}`;
  const modalClass = modalImage ? styles.modalOverride : styles.closeButtonOverride;

  const setTemplateImage = () => {
    if ( (/^(www\.)?pbsparents\.org$/i).test(hostname) ||
      ((/^(www\.)?pbs\.org$/i).test(hostname) && (/^\/parents(\/.*)?$/i).test(pathname))
    ) {
      return '/puma/images/logos/PBSKidsForParentsLogoReverse.svg';
    } else if ( (/^(www\.)?pbslearningmedia\.org$/i).test(hostname) ) {
      return '/puma/images/logos/PBSLmLogoOpenArea.svg';
    } else if ( (/^(www\.)?pbs\.org$/i).test(hostname) ) {
      return '/puma/images/logos/PBSLogo.svg';
    } else {
      return linkLogo;
    }
  };

  const ModalType = () => {
    if (modalImage) {
      return (
        <BridgeModalSponsor
          link={ href }
          title={ title }
          image={ modalImage }
          imageAltText={ imageAltText || title || ''}
          header={ modalHeader }
          body={ modalBody }
        />
      );
    } else {
      return (
        <BridgeModal
          link={ href }
          title={ title }
          image={ setTemplateImage() }
        />
      );
    }
  };

  return <>
    <Modal toggleButtonRef={ ref } modalOverrideClass={modalClass}>
      <ModalType/>
    </Modal>

    { clickAnimation ?
      <ClickAnimation>
        <button ref={ ref } className={buttonClass}>
          { children }
        </button>
      </ClickAnimation> :
      <button ref={ ref } className={buttonClass}>
        { children }
      </button>
    }
  </>;
};

export default BridgeLink;
