import Router from 'next/router';
import { NextRouter } from 'next/router';

type RouterEventName =
  'routeChangeStart' |
  'beforeHistoryChange' |
  'routeChangeComplete' |
  'routeChangeError' |
  'hashChangeStart' |
  'hashChangeComplete';

type RouterEventHandler = (
  _url: string,
  { _shallow }: { _shallow: boolean }
) => void;

type RouterEventQueueItem = {
  name: RouterEventName,
  fn: RouterEventHandler,
};

const routerEventQueues: Array<RouterEventQueueItem> = [];

// This function is used to handle router events without accidentally leaving multiple listeners in place
const handleRouterEventSafely = (name: RouterEventName, fn: RouterEventHandler) => {
  const lastEvent = routerEventQueues.find((item) => item.name === name);
  if (lastEvent) {
    Router.events.off(
      name,
      lastEvent.fn,
    );
    routerEventQueues.splice(
      routerEventQueues.indexOf(lastEvent),
      1,
    );
  }
  routerEventQueues.push({
    name,
    fn,
  });
  Router.events.on(name, fn);
};

const getQueryParam = (router: NextRouter, name: string) => {
  return (
    router.query[name] ||
    router.asPath.match(new RegExp(`\\?.*${name}=([^$\&]+)`))?.[1] || ''
  ) as string;
};

export {
  getQueryParam,
  handleRouterEventSafely,
};
