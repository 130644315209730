import React, { useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styles from './BridgeModalSponsor.module.scss';
import LanguageLocalizer from '@/utils/language-localization';

interface Props {
  link: string
  title: string
  image: string
  imageAltText: string
  header?: string
  body?: string
  buttonText?: string
}

const BridgeModalSponsor: React.FC<Props> = ({
  link,
  title,
  image,
  imageAltText,
  header,
  body,
  buttonText = 'Learn More',
}) => {
  const { __t } = new LanguageLocalizer( useContext, 'components/modules/BridgeModal' );
  return (
    <div className={styles.container}>
      <div className={styles.contentPanel}>
        <h3>{header}</h3>
        <p>{body}</p>
        <Link
          href={link}
          title={title}
          target='_blank'
          className={styles.linkButton}
          aria-label={`${__t('linkText')} ${title}. Opens in new window.`}
        >{buttonText}
        </Link>
      </div>
      <Link
        href={link}
        title={title}
        target='_blank'
        className={styles.imageLink}
        aria-label={`${__t('linkText')} ${title}. Opens in new window.`}
      >
        <Image
          src={image}
          alt={imageAltText || title || ''}
          width='450'
          height='450'
        />
      </Link>
    </div>
  );
};

export default BridgeModalSponsor;
