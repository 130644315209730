import { serialize } from 'tinyduration';
import { Maybe } from '@/types/pbskids-graph';

const getHours = (secs: number) => Math.floor(Math.round(secs) / 3600);
const getMinutes = (secs: number) => Math.floor(Math.round(secs) / 60) % 60;
const getSeconds = (secs: number) => Math.round(secs) % 60;

const formatTimestamp = (secs: number | string) => {
  let secondsNum;
  if (typeof secs === 'string') {
    secondsNum = parseInt(secs, 10);
  } else {
    secondsNum = secs;
  }

  if (typeof secondsNum !== 'number' || isNaN(secondsNum) || secondsNum <= 0) {
    return '00:00';
  }

  const hours = getHours(secondsNum);
  const minutes = getMinutes(secondsNum);
  const seconds = getSeconds(secondsNum);

  return [ hours, minutes, seconds ]
    .map((v) => v < 10 ? '0' + v : v)
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

const convertTimeToIsoDate = (timestamp: Maybe<number> | undefined) => {
  if (timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toISOString();
  }
};

const serializeDuration = (duration: Maybe<number> | undefined) => {
  if (duration) {
    const hours = getHours(duration);
    const minutes = getMinutes(duration);
    const seconds = getSeconds(duration);

    return serialize({ hours: hours, minutes: minutes, seconds: seconds });
  }
};

export {
  convertTimeToIsoDate,
  formatTimestamp,
  getHours,
  getMinutes,
  getSeconds,
  serializeDuration,
};
