import React, { useRef, useEffect, useState, ReactNode } from 'react';

interface Props {
  children: ReactNode,
  animationName?: string,
  onClick?: () => void,
}

const ClickAnimation: React.FC<Props> = ({
  children,
  animationName = 'squish',
  onClick,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [ animation, setAnimation ] = useState('');
  const [ prevAnimation, setPrevAnimation ] = useState('up');
  const [ isDown, setIsDown ] = useState(false);
  const [ onClickCalled, setOnClickCalled ] = useState(false);

  const resetClickState = () => {
    setOnClickCalled(false);
  };

  const resetAnimationState = () => {
    if (prevAnimation !== 'up') {
      setAnimation('');
      setPrevAnimation('up');
    }
  };

  const handlePointerDown = () => {
    if (prevAnimation === 'up') {
      setAnimation(animationName + '--down');
      setPrevAnimation('down');
    }
  };

  const handlePointerUp = () => {
    if (prevAnimation === 'down') {
      setAnimation(animationName + '--up');
      setPrevAnimation('up');
    }
    if (onClick && !onClickCalled) {
      onClick();
      setOnClickCalled(true);
      setTimeout(resetClickState, 100);
    }
  };

  const handleTransitionEnd = () => {
    if (prevAnimation === 'down') {
      setAnimation(animationName + '--down');
      setPrevAnimation('down');
    } else {
      setAnimation('');
    }
  };

  const handlePointerEnter = () => {
    if (isDown) {
      resetAnimationState();
    }
  };

  const handleBodyPointerDown = () => {
    setIsDown(true);
  };

  const handleBodyPointerUp = () => {
    setIsDown(false);
  };

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.addEventListener('pointerdown', handlePointerDown);
      target.addEventListener('pointerup', handlePointerUp);
      target.addEventListener('pointerleave', resetAnimationState);
      target.addEventListener('pointerenter', handlePointerEnter);
      target.addEventListener('transitionend', handleTransitionEnd);
      target.addEventListener('animationend', handleTransitionEnd);
      document.body.addEventListener('pointerdown', handleBodyPointerDown);
      document.body.addEventListener('pointerup', handleBodyPointerUp);
    }

    return () => {
      target?.removeEventListener('pointerdown', handlePointerDown);
      target?.removeEventListener('pointerup', handlePointerUp);
      target?.removeEventListener('pointerleave', resetAnimationState);
      target?.removeEventListener('pointerenter', handlePointerEnter);
      target?.removeEventListener('transitionend', handleTransitionEnd);
      target?.removeEventListener('animationend', handleTransitionEnd);
      document.body.removeEventListener('pointerdown', handleBodyPointerDown);
      document.body.removeEventListener('pointerup', handleBodyPointerUp);
    };
  });

  return (
    <div
      ref={ref}
      className={`${animationName} ${animation || ''}`}
    >
      {children}
    </div>
  );
};

export default ClickAnimation;
