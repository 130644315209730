/* eslint-disable no-unused-vars */

enum PerformanceCheckResultValue {
  INCONCLUSIVE = -1,
  RUNNING_NOW = -2,
  DID_NOT_RUN = -3,
  LEVEL_ONE = 1,
  LEVEL_THREE = 3,
  LEVEL_TWO = 2,
}

enum PerformanceLevelReason {
  DID_NOT_RUN = 'Tests did not run',
  INVALID_LEVEL = 'Invalid performance level, reverted to default',
  VALID_QUERY_STRING = 'Set in query string',
  TESTS_RUNNING = 'Tests not yet completed',
  NO_USABLE_RESULT = 'No usable test results found',
}

type PerformanceCheckResultValueType =
  PerformanceCheckResultValue.INCONCLUSIVE |
  PerformanceCheckResultValue.RUNNING_NOW |
  PerformanceCheckResultValue.DID_NOT_RUN |
  PerformanceCheckResultValue.LEVEL_ONE |
  PerformanceCheckResultValue.LEVEL_TWO |
  PerformanceCheckResultValue.LEVEL_THREE;

type PerformanceCheckTestStateSetter = (_value: PerformanceCheckResultValue) => void;

type PerformanceCheckTestResult = {
  value?: PerformanceCheckResultValue,
  reason: string,
  timestamp?: number,
  duration?: number,
};

type PerformanceCheckSpecificTestResult = {
  name: string,
  result: PerformanceCheckTestResult,
};

type PerformanceCheckTestFinalResults = {
  level: PerformanceCheckResultValue,
  reason: string,
  individualResults: Array<PerformanceCheckSpecificTestResult>,
  duration: number,
};

type PerformanceCheckMethod = {
  description?: string,
  method: () => Promise<PerformanceCheckTestResult>,
  skip?: boolean,
};

type PerformanceCheckTest = {
  name: string,
  skip?: boolean,
  methods: Array<PerformanceCheckMethod>,
  result?: PerformanceCheckTestResult,
};

export type {
  PerformanceCheckMethod,
  PerformanceCheckResultValueType,
  PerformanceCheckSpecificTestResult,
  PerformanceCheckTest,
  PerformanceCheckTestFinalResults,
  PerformanceCheckTestResult,
  PerformanceCheckTestStateSetter,
};

const getEnumName = (value: PerformanceCheckResultValueType) => {
  const index = (value as unknown as keyof typeof PerformanceCheckResultValue);
  return PerformanceCheckResultValue[index];
};

const levelExists = (value: PerformanceCheckResultValueType | string) => {
  if (parseInt(value.toString()) in PerformanceCheckResultValue) {
    return true;
  }
};

const isValidLevel = (value: PerformanceCheckResultValueType | string) => {
  return value.toString().match(/^[\d]+$/) && levelExists(value) && parseInt(value.toString()) > 0;
};

export {
  PerformanceCheckResultValue,
  PerformanceLevelReason,
  getEnumName,
  isValidLevel,
};
