import { deepValue } from '@/utils/deep-value';
import allLocales from './config-all-locales.json';

const isUnitTestSuite = process.env.NODE_ENV === 'test';
const isDevelopment = process.env.NODE_ENV === 'development';

const languageNameTranslations = [
  {
    shortCode: 'en',
    en: 'English',
    es: 'Inglés',
  },
  {
    shortCode: 'es',
    en: 'Spanish',
    es: 'Español',
  },
];

type Dictionary = {
  [key: string]: string | number | Dictionary,
};

type DynamicValues = {
  [key: string]: string | number,
};

type Locale = {
  defaultLocale: boolean,
  graphLocale: string,
  label: string,
  shortCode: string,
  longCode: string,
};

type Translator = (_textKey: string, _dynamicValues?: DynamicValues, _alternateLocale?: string) => string;

const defaultLocale = allLocales.find((locale: Locale) => locale.defaultLocale)?.longCode || 'en-US';

const getLocaleByLongCode = (localeLongCode: string = defaultLocale) => {
  const locale = allLocales.find((locale) => locale.longCode === localeLongCode);
  if (!locale) {
    throw new Error(`Unsupported locale long code: ${localeLongCode}`);
  }
  return locale;
};

const getLocaleByShortCode = (localeShortCode: string) => {
  const locale = allLocales.find((locale) => locale.shortCode === localeShortCode);
  if (!locale) {
    throw new Error(`Unsupported locale short code: ${localeShortCode}`);
  }
  return locale;
};

const translateTextCache: { [cacheKey: string]: string } = {};

const translateText = (
  locale: string = defaultLocale,
  dictionary: Dictionary,
  path: string,
  dynamicValues: DynamicValues = {},
) => {
  const fullPath = `${locale}.${path}`;
  const usingDynamic = Object.keys(dynamicValues).length > 0;

  if (!usingDynamic && translateTextCache[fullPath]) {
    return translateTextCache[fullPath];
  }

  const translatedText = deepValue(dictionary, `${fullPath}`);

  if (!translatedText) {
    throw new Error(`Missing translation for ${fullPath}`);
  }

  let text = translatedText;

  if (!dynamicValues.localeName) {
    try {
      dynamicValues.localeName = getLocaleByLongCode(locale).label;
    } catch (err) {
      dynamicValues.localeName = getLocaleByShortCode(locale).label;
    }
  }

  for (const key of Object.keys(dynamicValues)) {
    let value = dynamicValues[key];
    if (typeof value === 'number') {
      value = value.toLocaleString(locale);
    }
    text = text.replace(`{{${key}}}`, value);
  }

  const unreplaced = text.match(/\{\{[^\}]+\}\}/g);
  if (unreplaced) {
    throw new Error(`Missing dynamic values for ${unreplaced.join(', ')}`);
  }

  if (!usingDynamic) {
    translateTextCache[fullPath] = text;
  }

  return text;
};

const translationCheckCache: { [cacheKey: string]: boolean } = {};

const checkForMissingTranslations = (dictionary: Dictionary, cacheKey: string) => {
  if (!translationCheckCache[cacheKey]) {
    const locales = Object.keys(dictionary);
    for (const locale of locales) {
      const textKeys = Object.keys(dictionary[locale]);
      for (const textKey of textKeys) {
        for (const otherLocale of locales) {
          if (otherLocale !== locale && !deepValue(dictionary, `${otherLocale}.${textKey}`)) {
            throw new Error(`Translation missing for ${otherLocale}.${textKey}`);
          }
        }
      }
    }
    translationCheckCache[cacheKey] = true;
  }
};

const translatorsCache: { [cacheKey: string]: Translator } = {};

const createTranslator = (locale: string, dictionaryIn: Dictionary, componentPath?: string) => {
  const cacheKey = componentPath ? locale + '.' + componentPath : JSON.stringify({ locale, dictionaryIn });

  if (translatorsCache[cacheKey]) {
    return translatorsCache[cacheKey];
  }

  const dictionary = Object.assign({}, dictionaryIn);

  if (!isDevelopment) {
    // Check the other dictionary for missing keys unless active development is happening.
    // This allows developers to mockup features without having to translate everything up front.
    checkForMissingTranslations(dictionary, cacheKey);
  }

  // Let's allow for the locale shortCode to be used as a fallback.
  // For example, a locale of `en-US` will fall back to `en`.
  for (const locale of allLocales) {
    const { shortCode, longCode } = locale;
    if (!dictionary[longCode] && dictionary[shortCode]) {
      dictionary[longCode] = dictionary[shortCode];
    }
  }

  const translator: Translator = (textKey: string, dynamicValues?: DynamicValues, alternateLocal?: string) =>
    translateText(alternateLocal ?? locale, dictionary, textKey, dynamicValues);

  return translatorsCache[cacheKey] = translator;
};

const availableLocales = allLocales.map((locale) => {
  return {
    slug: locale.longCode,
    label: locale.label,
  };
});

const getShortLocale = (localeLongCode: string = defaultLocale) => {
  const locale = allLocales.find((locale) => locale.longCode === localeLongCode);
  if (!locale) {
    throw new Error(`Unsupported locale long code when getting short code: ${localeLongCode}`);
  }
  return locale.shortCode;
};

const getAvailableLocales = () => {
  const globallyAllowedLocales = (process?.env?.availableLocales || defaultLocale).split(',');
  return availableLocales.filter((locale) => {
    return globallyAllowedLocales.indexOf(locale.slug) !== -1;
  });
};

const getLocaleConfig = () => {
  if (!isUnitTestSuite) {
    throw new Error('getLocaleConfig can only be called by unit tests.');
  }
  return allLocales;
};

const loadOneDictionary = (componentPath: string, locale: string) => {
  return require(`../../${componentPath}/translations/${locale}.json`);
};

const dictionaryCache: { [cacheKey: string]: Dictionary } = {};

const getAllDictionaries = (path: string) => {
  const dictionary: Dictionary = {};

  if (dictionaryCache[path]) {
    return dictionaryCache[path];
  }

  for (const locale of allLocales) {
    try {
      dictionary[locale.longCode] =
        loadOneDictionary(path, locale.longCode);
    } catch (e) {
      try {
        dictionary[locale.shortCode] =
          loadOneDictionary(path, locale.shortCode);
      } catch (e) {
        throw new Error(`Cannot load ${locale.longCode} dictionary in ${path}!`);
      }
    }
  }

  return dictionaryCache[path] = dictionary;
};

const getLocaleFromRoutePath = (path: string) => {
  if (!path.startsWith('/')) {
    throw new Error(`Invalid route path "${path}" when getting locale.`);
  }
  const firstSegment = path.split('/')[1];
  if (!firstSegment) {
    return defaultLocale;
  }

  const found = allLocales.find((locale) => locale.longCode === firstSegment)?.longCode ??
    allLocales.find((locale) => locale.shortCode === firstSegment)?.longCode;

  return found || defaultLocale;
};

const ensureShortLocale = (originalLocale: string) => {
  if (originalLocale.length > 2) {
    return getShortLocale(originalLocale);
  }
  if (!allLocales.find((locale) => locale.shortCode === originalLocale)) {
    throw new Error(`Unsupported locale when ensuring short code: ${originalLocale}`);
  }
  return originalLocale;
};

const ensureLongLocale = (originalLocale: string) => {
  if (originalLocale.length > 2) {
    return getLocaleByLongCode(originalLocale).longCode;
  }
  const locale = allLocales.find((locale) => locale.shortCode === originalLocale);
  if (locale) {
    return locale.longCode;
  } else {
    throw new Error(`Unsupported locale when ensuring long code: ${originalLocale}`);
  }
};

const translateLanguageName = (localeOfReferencedLanguage: string, localeForReader: string) => {
  const refShortCode = ensureShortLocale(localeOfReferencedLanguage);
  const readerShortCode = ensureShortLocale(localeForReader);

  const languageNameTranslation =
    languageNameTranslations.find((translation) => translation.shortCode === refShortCode);

  return deepValue(languageNameTranslation, readerShortCode);
};

export type {
  Dictionary,
  Translator,
};

export {
  checkForMissingTranslations,
  createTranslator,
  defaultLocale,
  ensureLongLocale,
  ensureShortLocale,
  getAllDictionaries,
  getAvailableLocales,
  getLocaleByLongCode,
  getLocaleByShortCode,
  getLocaleConfig,
  getLocaleFromRoutePath,
  getShortLocale,
  translateLanguageName,
};
