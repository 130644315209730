import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import Logger from '@/utils/logger';

import {
  autoLocalizeToStation,
  getStationsByZipCode,
  isValidZipCode,
  localizeViaRequestParams,
  setAutoLocalizationCookie,
  setStationCookies,
} from '@/managers/StationLocalization';

import { Station } from '@/types/stations';

const logger = new Logger({ caller: 'contexts.StationLocalization.index' });

type StationLocalizationContextType = {
  setStation: Dispatch<SetStateAction<Station>>;
  setZipCode: Dispatch<SetStateAction<string>>;
  station: Station;
  stationError: boolean;
  stationList: Station[];
  zipCode: string;
};

const StationLocalizationContext = createContext<StationLocalizationContextType>({} as StationLocalizationContextType);
export default StationLocalizationContext;

export const StationLocalizationProvider = ({ children }: { children: ReactNode }) => {
  const [ station, setStation ] = useState<Station>({
    callSign: '',
    commonName: '',
    logos: {
      color: null,
      white: null,
    },
    siteUrl: null,
    timezone: null,
    tvssUrl: null,
    zipCode: null,
    theme: [],
  });

  const [ stationError, setStationError ] = useState<boolean>(false);
  const [ stationList, setStationList ] = useState<Station[]>([]);
  const [ zipCode, setZipCode ] = useState<string>('');
  const firstRun = useRef<boolean>(true);
  const localized = useRef<boolean>(false);
  const autoSetZipCode = useRef<boolean>(false);

  useEffect(() => {
    // Only run once on page load.
    if (!firstRun.current) return;
    firstRun.current = false;

    logger.debug('Localize onPageLoad Effect()', { localized: localized.current });
    if (localized.current) return;

    (async () => {
      const localizedResults = await localizeViaRequestParams();
      if (localizedResults) {
        localized.current = true;
        setStation(localizedResults.station);

        if (localizedResults.zipCode) {
          // Set autoSetZipCode to false to trigger getting station list after zipcode is updated.
          autoSetZipCode.current = false;
          setZipCode(localizedResults.zipCode);
        }
      }

      if (localized.current) return;

      const resp = await autoLocalizeToStation();

      if (resp.stations.length) {
        autoSetZipCode.current = true;
        setStation(resp.stations[0]);
        setStationList(resp.stations);
        setZipCode(resp.zipcode);
        setAutoLocalizationCookie(true);
        localized.current = true;
      } else {
        setStationError(true);
      }
    })();
  }, []);

  useEffect(() => {
    // Only run if zipCode is updated. Don't automatically run on load.
    if (!isValidZipCode(zipCode)) return;

    logger.debug('Zip Code Change Effect()', { zipCode, autoSetZipCode: autoSetZipCode.current });

    if (autoSetZipCode.current === false) {
      // Don't update the station list if the zip code
      // was not changed by a user interaction.
      (async () => {
        const foundStations = await getStationsByZipCode(zipCode);
        setStationList(foundStations);

        // Only set the zip code cookie if there are stations for the region.
        if (foundStations.length) setStationCookies({ zipCode });
      })();
    }

    // Reset autoSetZipCode.
    autoSetZipCode.current = false;
  }, [ zipCode ]);

  return <>
    <StationLocalizationContext.Provider value={{
      setStation,
      setZipCode,
      station,
      stationError,
      stationList,
      zipCode,
    }}>
      { children }
    </StationLocalizationContext.Provider>
  </>;
};
