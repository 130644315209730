import { deepValue } from '@/utils/deep-value';
import { PerformanceCheckResultValue, PerformanceCheckTest } from '../types';

const preferredBatteryLevel = .5;

const lowBattery: PerformanceCheckTest = {
  name: 'Battery Level',
  methods: [
    {
      method: async () => {
        const navigator = deepValue(window, 'navigator');

        if (typeof navigator?.getBattery !== 'function') {
          throw new Error('Browser does not support Battery Status API');
        }

        const battery = await navigator.getBattery();
        const reason = `Battery level: ${battery.level * 100}% + ${battery.charging ? 'charging' : 'discharging'}`;

        if (battery.level < preferredBatteryLevel && !battery.charging) {
          return {
            value: PerformanceCheckResultValue.LEVEL_TWO,
            reason: `Discharging and < ${preferredBatteryLevel * 100}% | ${reason}`,
          };
        }
        return {
          value: PerformanceCheckResultValue.LEVEL_THREE,
          reason,
        };
      },
    },
  ],
};

const reducedMotion: PerformanceCheckTest = {
  name: 'Reduced Motion System Setting',
  methods: [
    {
      method: async () => {
        const matchMedia = window.matchMedia('(prefers-reduced-motion: reduce)');
        const enabled: boolean = (
          matchMedia as unknown === true ||
          matchMedia.matches === true
        );
        const value = enabled ? PerformanceCheckResultValue.LEVEL_ONE : PerformanceCheckResultValue.LEVEL_THREE;
        const reason = `Enabled: ${enabled.toString()}`;

        return {
          value,
          reason,
        };
      },
    },
  ],
};

const deviceRAM: PerformanceCheckTest = {
  name: 'Device RAM',
  methods: [
    {
      method: async () => {
        const ramInGigs = deepValue(window, 'navigator.deviceMemory');
        let value = PerformanceCheckResultValue.LEVEL_THREE;
        const reason = `Device RAM: ${ramInGigs} GB`;

        if (typeof ramInGigs === 'undefined') {
          throw new Error('Browser does not support Navigator API: deviceMemory');
        }

        if (ramInGigs < .25) {
          value = PerformanceCheckResultValue.LEVEL_ONE;
        } else if (ramInGigs < 2) {
          value = PerformanceCheckResultValue.LEVEL_TWO;
        }

        return {
          value,
          reason,
        };
      },
    },
  ],
};

export {
  lowBattery,
  deviceRAM,
  reducedMotion,
};
