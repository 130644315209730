import React, { useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import BackArrow from '@/public/puma/images/icons/BridgeBackArrow.svg';
import LanguageLocalizer from '@/utils/language-localization';
import StyledIcon from '@/components/base/StyledIcon';
import styles from './BridgeModal.module.scss';

interface Props {
  link: string
  title: string
  image?: string
}

const BridgeModal: React.FC<Props> = ({
  link,
  title,
  image,
}) => {
  const { __t } = new LanguageLocalizer( useContext, 'components/modules/BridgeModal' );

  const LinkType = () => {
    if (image) {
      return (
        <Link
          href={link}
          title={title}
          target='_blank'
          className={styles.imageContainer}
        >
          <Image
            src={image}
            alt={title}
            width={300}
            height={100}
            className={styles.imageContainer}
          />
        </Link>
      );
    } else {
      return (
        <Link
          href={link}
          title={title}
          target='_blank'
          className={styles.textLink}
          aria-label={`${__t('linkText')} ${title}. Opens in new window.`}
        >
          {__t('linkText')}{link}
        </Link>
      );
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{__t('leavingText')} <span className={styles.titleAllCaps}>PBS Kids</span></h3>
      <LinkType/>
      <div className={styles.backArrow}>
        <StyledIcon
          icon={ BackArrow }
          classList={styles.backArrow}
        />
      </div>
    </div>
  );
};

export default BridgeModal;
