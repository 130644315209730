import localFont from 'next/font/local';
import styles from './fonts.module.scss';

const pbsKidsHeadlineBold = localFont({
  display: 'swap',
  variable: '--pbsk-font-family-headline',
  style: 'normal',
  fallback: [
    'arial',
    'helvetica',
    'sans-serif',
  ],
  src: [
    {
      path: './assets/pbskidsheadline-bold.woff',
      weight: '700',
    },
  ],
});

const pbsSans = localFont({
  display: 'swap',
  variable: '--pbsk-font-family-sans',
  fallback: [
    '-apple-system',
    'blinkmacsystemfont',
    'Segoe UI',
    'Helvetica Neue',
    'arial',
    'sans-serif',
  ],
  src: [
    {
      path: './assets/PBSSans.woff2',
      weight: '400',
    },
    {
      path: './assets/PBSSans.woff2',
      weight: '700',
    },
  ],
});

const pbsSansBold = localFont({
  display: 'swap',
  variable: '--pbsk-font-family-sans-bold',
  fallback: [
    'arial',
    'helvetica',
    'sans-serif',
  ],
  src: [
    {
      path: './assets/PBSSans-Bold.woff2',
      weight: '700',
    },
  ],
});

const fontClasses = [
  pbsKidsHeadlineBold.variable,
  pbsSans.variable,
  pbsSansBold.variable,
  styles.rootFontWrapper,
];

export {
  fontClasses,
};
