import { FocusEvent, ReactNode, useCallback, useEffect, useRef } from 'react';

type DialogPanelProps = {
  children?: ReactNode,
  closeDialogMethod?: () => void,
  label?: string,
}

// This component will help us create more accessible modals and dialogs.
// Based on https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/

export default function DialogPanel({ children, label, closeDialogMethod }: DialogPanelProps) {
  const panelRef = useRef<HTMLDivElement>(null);
  const firstChildRef = useRef<HTMLElement|null>(null);
  const lastChildRef = useRef<HTMLElement|null>(null);
  const tabTrapRef = useRef<HTMLDivElement>(null);

  const handleEscape = useCallback((ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      closeDialogMethod?.();
    }
  }, [ closeDialogMethod ]);

  const trapFocus = (ev: FocusEvent<HTMLDivElement, Element>) => {
    if (!firstChildRef.current) {
      const focusable = panelRef?.current?.querySelectorAll('button, [href], input, select, textarea, [tabindex="0"]:not(:last-of-type)');

      if (focusable?.length) {
        firstChildRef.current = focusable[0] as HTMLElement;
        lastChildRef.current = focusable[focusable.length - 1] as HTMLElement;
      }
    } else if (ev.target === panelRef.current) {
      lastChildRef.current?.focus();
    } else if (ev.target === tabTrapRef.current) {
      firstChildRef.current?.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleEscape);
    panelRef.current?.focus();
    return () => {
      document.removeEventListener('keyup', handleEscape);
    };
  }, [ handleEscape ]);

  return (
    <div
      ref={panelRef}
      aria-label={label}
      onFocus={trapFocus}
      role='dialog'
      tabIndex={0}
    >
      {children}
      <div ref={tabTrapRef} tabIndex={0} />
    </div>
  );
}
