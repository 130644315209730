/* eslint-disable @typescript-eslint/no-explicit-any */

import { getEnvData } from '@/utils/env';
import { logWithColor } from './colors';
import { queryStringWrapper, localStorageWrapper } from '@/utils/browser-state';

const envData = getEnvData();
const { packageName, packageVersion, buildId, buildTime } = envData;

const envName = envData.NODE_ENV;
const isProdMode = envName === 'production';
const isUnitTestSuite = envName === 'test';
const isBuilding = typeof process !== 'undefined' && process.env.npm_lifecycle_script === 'next build';
const isRunningInBrowser = typeof window !== 'undefined';

const allMessageTypes = [
  'log',
  'info',
  'warn',
  'debug',
  'error',
  'table',
];

const logLevels: {
  [key: string]: Array<string>,
} = {
  'off': [],
  'verbose': allMessageTypes,
  'info': [
    'log',
    'info',
    'table',
  ],
  'debug': [
    'debug',
  ],
  'warn': [
    'warn',
  ],
  'error': [
    'error',
  ],
};

const noop = () => null;

let welcomed = false;

const getDefaultLevel = () => {
  if (!isRunningInBrowser && envData.LOG_LEVEL) {
    return envData.LOG_LEVEL;
  }

  return 'verbose';
};

const getBrowserLevelsOverride = () => {
  if (isRunningInBrowser) {
    const qsp = queryStringWrapper.get('pbskloglevel');
    const localStorage = (localStorageWrapper.get('pbskloglevel') || '').toString();

    if (typeof qsp === 'string' && qsp.length) {
      return qsp.split(',');
    } else if (localStorage !== 'null' && localStorage.length) {
      return localStorage.split(',');
    } if (isProdMode) {
      // If no query string or localStorage override,
      // turn off frontend logging on prod.
      return 'off';
    }
  }

  return null;
};

const getTypesToOutput = (levelsPassedIn?: Array<string> | string) => {
  const levelsMixed = getBrowserLevelsOverride() || levelsPassedIn || getDefaultLevel();
  const levelsArray = Array.isArray(levelsMixed) ? levelsMixed : [ levelsMixed ];
  const levels = levelsArray.filter((level: string) => !!level);

  const messageTypesToOutput: Array<string> = [];
  for (const level of levels) {
    if (logLevels[level]) {
      logLevels[level].forEach((type: string) => {
        if (messageTypesToOutput.indexOf(type) === -1) {
          messageTypesToOutput.push(type);
        }
      });
    }
  }
  return messageTypesToOutput;
};

const welcomeMessage = () => {
  const alertString = [];
  const types = getTypesToOutput();

  if (types.length > 0) {
    alertString.push(
      `${packageName} v${packageVersion}`,
    );

    alertString.push(`logging enabled for: ${types.join(', ')}`);

    if (buildId) {
      alertString.push(`buildId: ${JSON.stringify(buildId)}`);
    }

    if (buildTime) {
      alertString.push(`buildTime: ${JSON.stringify(buildTime)}`);
    }

    alertString.push(`Env: ${envName}`);

    logWithColor(`[ ${alertString.join(' | ')} ]`, 'info');
  }
};

class Logger {
  log: (..._args: any[]) => void = noop;
  info: (..._args: any[]) => void = noop;
  debug: (..._args: any[]) => void = noop;
  warn: (..._args: any[]) => void = noop;
  error: (..._args: any[]) => void = noop;
  table: (..._args: any[]) => void = noop;

  constructor(
    {
      caller = 'unknown',
      levels,
    }: {
      caller: string,
      levels?: Array<string> | string
    },
  ) {
    this.defineClassMethods(
      caller,
      getTypesToOutput(levels),
    );

    if (isRunningInBrowser && !isUnitTestSuite && !isBuilding && !welcomed) {
      this.welcomeMessage();
      welcomed = true;
    }
  }

  welcomeMessage = welcomeMessage;

  defineClassMethods(caller: string, typesToOutput: Array<string>) {
    allMessageTypes.forEach((messageType) => {
      const shouldLog = typesToOutput.indexOf(messageType) !== -1;
      const callback = !shouldLog ? noop :
        (...args: any[]) => {
          const timestamp = new Date().toLocaleDateString('en-US', {
            second: 'numeric',
            minute: 'numeric',
            hour: 'numeric',
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
            hour12: false,
            timeZone: 'America/New_York',
            timeZoneName: 'short',
          });

          logWithColor(
            `[ ${caller} :: ${messageType} @ ${timestamp} ]`,
            messageType,
            args,
          );
        };

      (this as any)[messageType] = callback;
    });
  }
}

export default Logger;
