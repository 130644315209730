import { PerformanceCheckResultValue, PerformanceCheckTest } from '../types';

const webGlSupport: PerformanceCheckTest = {
  name: 'WebGL Support',
  methods: [
    {
      description: 'Canvas Context Test',
      method: async () => {
        let reason = 'WebGL is unsupported';
        let value = PerformanceCheckResultValue.LEVEL_TWO;

        // Based on code from:
        // https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/By_example/Detect_WebGL
        const canvas = document.createElement('canvas');
        const glContext = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

        if (glContext instanceof WebGLRenderingContext) {
          reason = 'WebGL is supported';
          value = PerformanceCheckResultValue.LEVEL_THREE;
        }

        return {
          value,
          reason,
        };
      },
    },
  ],
};

export {
  webGlSupport,
};
