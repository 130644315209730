import Cookies from 'js-cookie';
import getAutoLocalizedStation from '@/queries/station/getAutoLocalizedStation.graphql';
import getLocalizedStationByZipCode from '@/queries/station/getLocalizedStationByZipCode.graphql';
import getStationByCallSign from '@/queries/station/getStationByCallSign.graphql';
import { Localization, Maybe, PbsStation, StationImage } from '@/types/pbskids-graph';
import { Station, StationLogoTheme } from '@/types/stations';
import { queryStringWrapper } from '@/utils/browser-state';
import { queryGraphClient, queryGraphServer } from '@/utils/graphql';
import Logger from '@/utils/logger';

const logger = new Logger({ caller: 'managers.StationLocalization.index' });

enum StationCookies {
  KIDS_AUTOLOCALIZED_COOKIE = 'pbskids.autolocalized',
  KIDS_STATION_COOKIE = 'pbskids.station',
  PBS_STATION_COOKIE = 'pbsol.station',
  KIDS_ZIPCODE_COOKIE = 'pbskids.zipcode',
}

const getCookieAttr = () => ({
  expires: 365, // COOKIE_TTL_DAYS
  domain: (/pbskids\.org$/).test(window.location.hostname) ? '.pbskids.org' : window.location.hostname,
});

const getStationLogoUrl = (
  images: Maybe<Maybe<StationImage>[]> | undefined,
  logoTheme: StationLogoTheme,
): string | null => {
  // Check if station contains images.
  if (!images || !images.length) return null;

  // Attempt to get single-brand logo for the specified theme.
  let foundImage = images.find( (image) => image && image.profile === `${logoTheme}-single-brand-logo`);
  if (foundImage) return foundImage.url;

  // Attempt to get co-branded logo for the specified theme.
  foundImage = images.find( (image) => image && image.profile === `${logoTheme}-cobranded-logo`);
  if (foundImage) return foundImage.url;

  // Logo for the speficied theme not found.
  return null;
};

const reducePbsStation = (selectedStation: PbsStation): Station => {
  const reducedStation: Station = {
    callSign: selectedStation.call_sign || '',

    commonName: selectedStation.full_common_name ||
                selectedStation.short_common_name ||
                selectedStation.call_sign || '',

    logos: {
      color: getStationLogoUrl(selectedStation.images, StationLogoTheme.Color),
      white: getStationLogoUrl(selectedStation.images, StationLogoTheme.White),
    },

    siteUrl: selectedStation.station_kids_url ||
             selectedStation.website_url ||
             selectedStation.tvss_url || null,

    timezone: selectedStation.timezone || null,

    tvssUrl: selectedStation.tvss_url || null,

    zipCode: selectedStation.zip_code || null,

    theme: selectedStation.theme?.[0] ? [ selectedStation.theme[0] ]: [],
  };

  return reducedStation;
};

export const autoLocalizeToStation = async (): Promise<{stations: Station[], zipcode: string}> => {
  const { autoLocalization }: { autoLocalization: Localization } = await queryGraphClient(getAutoLocalizedStation)
    .catch(() => {
      return { autoLocalization: null };
    });

  let stations: Station[] = [];
  let zipcode = '';

  logger.debug('autoLocalizeToStation()', { autoLocalization });

  if ( autoLocalization ) {
    if ( autoLocalization.pbsStations?.[0] ) {
      stations = (autoLocalization.pbsStations as PbsStation[]).map(reducePbsStation);
    }

    zipcode = autoLocalization.zipcode || stations[0]?.zipCode || '';
  }

  return {
    stations,
    zipcode,
  };
};

export const getStationsByZipCode = async (zipCode: string): Promise<Station[]> => {
  const graphMethod = (typeof window !== 'undefined') ? queryGraphClient : queryGraphServer;
  const { localization }: { localization: Localization } =
    await graphMethod(getLocalizedStationByZipCode, { type: 'zipcode', value: zipCode });

  logger.debug('getStationsByZipCode()', { zipCode, localization });

  if ( localization?.pbsStations?.[0] ) {
    return (localization?.pbsStations as PbsStation[]).map(reducePbsStation);
  }

  return [];
};

export const getStationByCallsign = async (callsign: string): Promise<Station | undefined> => {
  const graphMethod = (typeof window !== 'undefined') ? queryGraphClient : queryGraphServer;
  const { pbsStation }: { pbsStation: PbsStation } = await graphMethod(getStationByCallSign, {
    callsign,
  });

  if ( pbsStation?.call_sign ) return reducePbsStation(pbsStation);

  return undefined;
};

export const setStationCookies = ({ callSign, zipCode }: {callSign?: string, zipCode?: string }): void => {
  if (callSign) Cookies.set(StationCookies.KIDS_STATION_COOKIE, callSign, getCookieAttr());
  if (zipCode) Cookies.set(StationCookies.KIDS_ZIPCODE_COOKIE, zipCode, getCookieAttr());
};

export const isValidZipCode = (zipcode: string | undefined): boolean => {
  return !!(zipcode && /\d{5}/.test(zipcode));
};

export const localizeViaRequestParams = async (): Promise<{station: Station, zipCode: string} | undefined> => {
  // [Localization order of priority](./docs/localization.md)
  let station: Station | undefined;
  let zipCodeCookie = Cookies.get(StationCookies.KIDS_ZIPCODE_COOKIE);
  const callSignsToTest: {name: string, value: string}[] = [
    {
      name: 'QSP: station',
      value: queryStringWrapper.get('station') || '',
    },
    {
      name: 'Cookie: ' + StationCookies.KIDS_STATION_COOKIE,
      value: Cookies.get(StationCookies.KIDS_STATION_COOKIE) || '',
    },
    {
      name: 'Cookie: ' + StationCookies.PBS_STATION_COOKIE,
      value: Cookies.get(StationCookies.PBS_STATION_COOKIE) || '',
    },
  ];

  if (!isValidZipCode(zipCodeCookie)) {
    zipCodeCookie = undefined;
  }

  // Attempt to localize via QSP and station cookies.
  logger.debug('localizeViaRequestParams()', { callSignsToTest });
  for (let i = 0; i < callSignsToTest.length; i++) {
    if (!callSignsToTest[i].value) continue;

    station = await getStationByCallsign(callSignsToTest[i].value);

    if (station) {
      logger.debug('localizeViaRequestParams():: Found Station', callSignsToTest[i]);
      return {
        station,
        zipCode: zipCodeCookie || station.zipCode || '',
      };
    }
  }

  // Attempt to localize via zipcode cookie.
  logger.debug('localizeViaRequestParams()', { zipCodeCookie });
  if (zipCodeCookie) {
    const stations = await getStationsByZipCode(zipCodeCookie);
    if (stations.length) {
      return {
        station: stations[0],
        zipCode: zipCodeCookie,
      };
    }
  }

  // Return undefined and allow for autolocalization.
  return undefined;
};

export const setAutoLocalizationCookie = (value: boolean): void => {
  Cookies.set(StationCookies.KIDS_AUTOLOCALIZED_COOKIE, value.toString(), getCookieAttr());
};

export const setConfirmedAutoLocalizationCookie = (callsign: string): void => {
  setAutoLocalizationCookie(true);

  Cookies.set(StationCookies.KIDS_STATION_COOKIE, callsign, getCookieAttr());
};

export const isAutoLocalizationConfirmed = (): boolean => {
  const autolocalized = Cookies.get(StationCookies.KIDS_AUTOLOCALIZED_COOKIE);
  const station = Cookies.get(StationCookies.KIDS_STATION_COOKIE);

  if (autolocalized === 'false') {
    return false;
  } else if (autolocalized === 'true') {
    return station !== undefined;
  }

  return false;
};
