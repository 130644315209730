import { MutableRefObject, useContext, useRef } from 'react';
import LanguageLocalizer from '@/utils/language-localization';
import Modal from '@/components/base/Modal';
import styles from './StationSettings.module.scss';
import StationPicker from './StationPicker';

type Props = {
  modalOverrideClass?: string,
  onClose?: () => void
  onOpen?: () => void
  suppressBubbleArrowStyle?: boolean,
  toggleButtonOverrideRef?: MutableRefObject<HTMLButtonElement>
  useModalAnimation?: boolean,
  wrapperClass?: string,
};

export default function StationSettings({
  modalOverrideClass,
  onClose,
  onOpen,
  suppressBubbleArrowStyle,
  toggleButtonOverrideRef,
  useModalAnimation,
  wrapperClass,
}: Props) {
  const defaultToggleButton = useRef({} as HTMLButtonElement);
  const { __t } = new LanguageLocalizer( useContext, 'components/modules/StationSettings' );

  return <>
    <div className={wrapperClass || ''}>
      <Modal
        dialogLabel={__t('changeStation')}
        toggleButtonRef={toggleButtonOverrideRef || defaultToggleButton}
        modalOverrideClass={modalOverrideClass || styles.modalOverride}
        overlayOverrideClass={styles.overlayOverride}
        onOpen={onOpen}
        onClose={onClose}
        useAnimation={useModalAnimation}
      >
        <StationPicker suppressBubbleArrowStyle={suppressBubbleArrowStyle} />
      </Modal>
      {!toggleButtonOverrideRef && <span>
        <button ref={defaultToggleButton}>{__t('changeStation')}</button>
      </span>}
    </div>
  </>;
}
