import { useContext } from 'react';
import Router from 'next/router';
import GlobalStateContext from '@/components/global/GlobalState';

import {
  createTranslator,
  defaultLocale,
  getAllDictionaries,
  getLocaleByLongCode,
  getLocaleFromRoutePath,
  Translator,
} from './system';

// Class members prefixed with a `_` are meant to be private and should never be used from outside this class.
class LanguageLocalizer {
  constructor(
    localeSource: string | typeof useContext,
    componentPath: string,
  ) {
    this._locale = defaultLocale;

    if (typeof localeSource === 'function') {
      // if passed a useContext function, use it to get GlobalStateContext
      const { locale, setLocale: setLocaleInGlobalState } = localeSource(GlobalStateContext);
      this._locale = locale;
      this._setLocaleInGlobalState = setLocaleInGlobalState;
    } else if (typeof localeSource === 'string' && localeSource.length > 0) {
      // if passed a string
      this._locale = localeSource;
    }

    this._translator = createTranslator(
      this._locale,
      getAllDictionaries(componentPath),
      componentPath,
    );

    this._keepUpdatedInGlobalState();
  }

  _locale: string;
  _setLocaleInGlobalState?: (_locale: string) => void;
  _translator: Translator;

  get __t() {
    return this._translator;
  }

  get locale(): string {
    return this._locale;
  }

  get graphLocale() {
    return getLocaleByLongCode(this._locale).graphLocale;
  }

  updateLocale(newLocale: string) {
    this._locale = newLocale;
    if (typeof this._setLocaleInGlobalState === 'function') {
      this._setLocaleInGlobalState(newLocale);
    }
  }

  _keepUpdatedInGlobalState() {
    // This is a workaround for an issue with clicking links that change the locale but not updating the global state.
    const setLocaleInGlobalState = this._setLocaleInGlobalState;

    if (typeof setLocaleInGlobalState === 'function') {
      Router.events.on('routeChangeComplete', (path: string) => {
        const localeInPath = getLocaleFromRoutePath(path);
        if (this._locale !== localeInPath) {
          this._locale = localeInPath;
          setLocaleInGlobalState( localeInPath );
        }
      });
    }
  }
}

export default LanguageLocalizer;
