import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import {
  getEnumName,
  isValidLevel,
  PerformanceCheckResultValue,
  PerformanceCheckResultValueType,
  PerformanceCheckSpecificTestResult,
  PerformanceCheckTestFinalResults,
  PerformanceLevelReason,
} from '@/managers/PerformanceCheck/types';

import { getQueryParam, handleRouterEventSafely } from '@/utils/router';
import { localStorageWrapper as localStorage } from '@/utils/browser-state';
import { makeResultsMoreLegible, runAllTests } from '@/managers/PerformanceCheck/system';
import GlobalStateContext from '@/components/global/GlobalState';
import styles from './PerformanceCheck.module.scss';

import Logger from '@/utils/logger';
const logger = new Logger({ caller: 'components.PerformanceCheck.index' });

type PerfLevel = PerformanceCheckResultValueType | string | number;

const defaultPerformanceCheckState: PerformanceCheckTestFinalResults = {
  level: PerformanceCheckResultValue.DID_NOT_RUN,
  reason: PerformanceLevelReason.DID_NOT_RUN as string,
  individualResults: [] as Array<PerformanceCheckSpecificTestResult>,
  duration: -1,
};

const testRunRateLimitMs = 1000;
let lastTestRun = -1;
let reactRenderDurationEnd = 0;
let reactRenderDurationStart = 0;
let rerenderCount = 0;

const outputDuration = (duration: number) => {
  return <span className={duration > 100 ? styles.errorText : ''}>
    {Math.round(duration).toLocaleString()}
  </span>;
};

const resetReactMetrics = () => {
  reactRenderDurationStart = performance.now();
  rerenderCount = 0;
};

export default function PerformanceCheck() {
  const router = useRouter();
  const { showDevUI } = useContext(GlobalStateContext);
  const wrapperReference: MutableRefObject<HTMLDivElement> = useRef({} as HTMLDivElement);

  const [ inited, setInited ] = useState(false);
  const [ collapsed, setCollapsed ] = useState(false);
  const [ levelResolved, setLevelResolved ] = useState(false);
  const [ showIndividualTestResults, setShowIndividualTestResults ] = useState(false);

  const { performanceCheckState, setPerformanceCheckState } = useContext(GlobalStateContext);

  if (reactRenderDurationStart === 0) {
    resetReactMetrics();
  }

  handleRouterEventSafely('routeChangeStart', () => {
    resetReactMetrics();
    setLevelResolved(false);
    reactRenderDurationEnd = performance.now();
    setInited(false);
    logger.debug('render onRouteChangeStart');
  });

  const performanceLevelReason = performanceCheckState.reason;
  const performanceLevelOverride = getQueryParam(router, 'performanceLevel');
  const performanceLevel = parseInt((performanceLevelOverride || performanceCheckState.level).toString());

  const cachedLevel = localStorage.get('lastPerformanceLevel');
  const runningNow = performanceLevel === PerformanceCheckResultValue.RUNNING_NOW;

  const overrideTestSet = getQueryParam(router, 'performanceCheckOnly').split(',').filter((val) => !!val);

  const resolveTheLevel = () => {
    setLevelResolved(true);
    reactRenderDurationEnd = performance.now();
  };

  const applyPerformanceState = useCallback((state: {
    level: PerfLevel,
    reason: string,
    individualResults?: Array<PerformanceCheckSpecificTestResult>,
    duration?: number,
  }) => {
    const level = state.level as PerformanceCheckResultValueType;

    setPerformanceCheckState(
      Object.assign( defaultPerformanceCheckState, state, { level }),
    );
  }, [
    setPerformanceCheckState,
  ]);

  handleRouterEventSafely('routeChangeComplete', () => {
    // If a link contains a Performance Level override as a query string param, it won't properly be applied without this:
    const override = getQueryParam(router, 'performanceLevel');

    if (override) {
      applyPerformanceState({
        level: override,
        reason: PerformanceLevelReason.VALID_QUERY_STRING,
      });
    }

    setShowIndividualTestResults(!override);
  });

  const setInvalidLevel = useCallback((level: PerfLevel, reason?: string) => {
    logger.warn(`Invalid performance level: ${level}`);

    applyPerformanceState({
      level: PerformanceCheckResultValue.LEVEL_ONE,
      reason: reason || PerformanceLevelReason.INVALID_LEVEL,
    });
  }, [ applyPerformanceState ]);

  const initUiAndRunTests = useCallback(() => {
    setCollapsed(localStorage.get('performanceCheckUiCollapsed') !== false);
    setInited(true);

    if (levelResolved) {
      return;
    }

    rerenderCount++;
    logger.debug('Re-rendering', { rerenderCount, inited, levelResolved });

    if (performanceLevelOverride) {
      if (isValidLevel(performanceLevelOverride)) {
        applyPerformanceState({
          level: performanceLevelOverride,
          reason: PerformanceLevelReason.VALID_QUERY_STRING,
        });
      } else {
        applyPerformanceState({
          level: PerformanceCheckResultValue.LEVEL_ONE,
          reason: `Invalid performance level override: ${performanceLevelOverride}`,
        });
        setInvalidLevel(performanceLevelOverride, `Invalid performance level override: ${performanceLevelOverride}`);
      }
      resolveTheLevel();
    } else if (window.navigator?.onLine === false && cachedLevel && isValidLevel(cachedLevel)) {
      // If no network connection, avoid changing the level if possible.
      applyPerformanceState({
        level: cachedLevel,
        reason: `No network connection available, but cached performance level is ${cachedLevel}`,
      });
      resolveTheLevel();
    } else if (!inited) {
      const now = performance.now();
      if (lastTestRun > 0 && now < lastTestRun + testRunRateLimitMs) {
        // If the last test run was recent, don't re-run the tests.
        return;
      }

      lastTestRun = now;

      applyPerformanceState({
        level: PerformanceCheckResultValue.RUNNING_NOW,
        reason: PerformanceLevelReason.TESTS_RUNNING,
      });

      runAllTests(overrideTestSet)
        .then((results: PerformanceCheckTestFinalResults) => {
          logger.debug(
            `Performance tests completed in ${results.duration} ms`,
            results.individualResults,
          );

          if (isValidLevel(results.level)) {
            localStorage.set(
              'lastPerformanceLevel',
              results.level,
            );
            applyPerformanceState(results);
            setShowIndividualTestResults(true);
          } else {
            setInvalidLevel(results.level);
          }

          resolveTheLevel();
        }).catch(() => {
          setInvalidLevel(PerformanceCheckResultValue.LEVEL_ONE);
          resolveTheLevel();
        });
    }
  }, [
    applyPerformanceState,
    cachedLevel,
    inited,
    levelResolved,
    overrideTestSet,
    performanceLevelOverride,
    setCollapsed,
    setInited,
    setInvalidLevel,
  ]);

  useEffect(initUiAndRunTests);

  if (showDevUI === false || !inited) {
    return <></>;
  }

  const toggleMenu = () => {
    setCollapsed(!collapsed);
    localStorage.set('performanceCheckUiCollapsed', !collapsed);
  };

  return <>
    <div
      ref={wrapperReference}
      data-collapsed={collapsed}
      className={styles.component}
      onClick={() => {
        if (collapsed) {
          toggleMenu();
        }
      }}
    >
      <div className={styles.largeText}>
        Performance Check
        {collapsed && isValidLevel(performanceLevel) &&
          <span className={styles.extraSmallText}> (current level: {performanceLevel})</span>
        }
      </div>

      {!collapsed && <>

        {levelResolved && <button
          className={styles.closeButtonClasses}
          onClick={toggleMenu}
        >
          Close
        </button>}

        {runningNow ?
          <p>Running performance tests...</p> :
          <p className={styles.extraSmallText}>
            <strong>`{getEnumName(performanceLevel)}`</strong>
            {performanceLevelReason && <>
              {' =>'} <strong>`{performanceLevelReason}`</strong>
            </>}.
          </p>}

        {levelResolved && <>
          <p className={styles.extraSmallText}>
            {performanceCheckState.duration > 0 ? <>
              <span>Performance Check tests ran in </span>
              <strong>{outputDuration(performanceCheckState.duration)} ms.</strong>
            </> : ''}
            <span> <strong>{rerenderCount + 1}</strong> React re-renders completed in </span>
            <strong>{outputDuration(reactRenderDurationEnd - reactRenderDurationStart)}</strong>
            <span> ms.</span>
          </p>
        </>}

        {showIndividualTestResults && performanceCheckState.individualResults.length > 0 ? <>
          <strong className={styles.smallText}>Individual test results:</strong>
          <pre className={styles.preClasses}>
            {JSON.stringify(makeResultsMoreLegible(performanceCheckState.individualResults), null, 1)}
          </pre>
        </> : ''}
      </>}
    </div>
  </>;
}

export { defaultPerformanceCheckState };
