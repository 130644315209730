import { FormEvent, useContext, useId, useState } from 'react';
import { isValidZipCode, setStationCookies } from '@/managers/StationLocalization';
import { Station } from '@/types/stations';
import Image from 'next/image';
import React from 'react';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import styles from './StationSettings.module.scss';

const StationLogo: React.FC<{station: Station}> = ({
  station,
}) => {
  return <>
    { !station.logos.color ? <></> :
      <Image
        src={ station.logos.color }
        alt=''
        width='80'
        height='80'
      />
    }
  </>;
};

enum ViewState {
  ViewSelectedStation,
  ViewStationsList,
  ViewZipCodeForm,
}

type Props = {
  suppressBubbleArrowStyle?: boolean,
};

export default function StationPicker({ suppressBubbleArrowStyle }: Props) {
  const { station, stationList, zipCode, setStation, setZipCode } = useContext(StationLocalizationContext);
  const [ viewState, setViewState ] = useState<ViewState>(ViewState.ViewSelectedStation);
  const [ zipInputValue, setZipInputValue ] = useState('');
  const zipcodeInputDescription = useId();
  const zipCodeInputId = useId();
  const zipcodeResultsId = useId();

  const handleStationSelected = (station: Station) => {
    // Update the station and send the user back to the station selected view.
    setStation(station);
    setViewState(ViewState.ViewSelectedStation);
    setStationCookies({ callSign: station.callSign });
  };

  const handleZipCodeChange = (e: FormEvent) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    const newZip = zipInputValue || zipCode;

    if (!isValidZipCode(newZip)) return;

    // Update the zip code, which in turn updates the station list,
    // and send the user back to the station list view.
    setZipCode(newZip);
    setViewState(ViewState.ViewStationsList);
  };

  return <>
    <div className={styles.stationPickerWrapper + (suppressBubbleArrowStyle ? '' : ' ' + styles.hasBubbleArrowStyle)}>
      <div>
        <header>
          <h4 className={styles.currentStation}>Your Local PBS Station</h4>
        </header>

        { viewState === ViewState.ViewSelectedStation &&
          <div className={styles.initialContainer}>
            <div className={styles.currentStationLogo}>
              <span className={styles.stationLogo}>
                <StationLogo station={station}></StationLogo>
              </span>
            </div>

            <div>
              <p className={styles.currentStationName}>{station.commonName}</p>
              <button className={styles.changeStationBtn} onClick={setViewState.bind(null, ViewState.ViewStationsList)}>
                Change Your Station
              </button>
            </div>
          </div>
        }

        { viewState === ViewState.ViewStationsList &&
          <div className={styles.selectStationContainer}>
            <h4 className={styles.selectStation}>
              {stationList.length ? 'Select your local station' : 'No station available'}
            </h4>

            <ul aria-labelledby={zipcodeResultsId}>
              {stationList.map( (station) => (
                <li key={station.callSign} onClick={handleStationSelected.bind(null, station)}>
                  <button>
                    <div className={styles.stationSquare}>
                      <span className={styles.stationLogo}>
                        <StationLogo station={station}></StationLogo>
                      </span>
                    </div>
                    <p className={styles.stationName}>{station.commonName}</p>
                  </button>
                </li>
              ))}
            </ul>

            <p className={styles.results}>
              <span id={zipcodeResultsId}>Results for {zipCode}</span>.
              <button onClick={setViewState.bind(null, ViewState.ViewZipCodeForm)}>&nbsp;Try another zip code</button>
            </p>
          </div>
        }

        { viewState === ViewState.ViewZipCodeForm &&
          <>
            <div>
              <form className={styles.zipCodeContainer} onSubmit={handleZipCodeChange}>
                <label htmlFor={zipCodeInputId} className={`${styles.selectStation} font-sans-bold`}>What is your zip code?</label>
                <p id={zipcodeInputDescription} className={styles.useZipCode}>
                  PBS KIDS uses your zip code to find our stations in your area.
                </p>
                <input id={zipCodeInputId} aria-describedby={zipcodeInputDescription} name='zipCode' type='text' className={styles.zipCodeInput} placeholder={zipCode} maxLength={5} value={zipInputValue} onChange={(e) => setZipInputValue(e.target.value)} />
                <button type='submit' className={styles.changeStationBtn} aria-label={'Find stations in ' + (zipInputValue || zipCode)}>GO</button>
              </form>
            </div>

            <button className={styles.changeStationBtn} onClick={setViewState.bind(null, ViewState.ViewStationsList)} aria-label='Back to select station'>
              Back
            </button>
          </>
        }

      </div>
    </div>
  </>;
}
