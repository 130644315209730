import {
  ApolloClient,
  ApolloClientOptions,
  DefaultOptions,
  DocumentNode,
} from '@apollo/client';

import { completionMessage, getHumanReadableErrorMessage, logger } from './feedback';
import { getEnvData, getSimplifiedDeployedEnvName } from '@/utils/env';
import { GraphQLError } from 'graphql';

import { sha256 } from '../sha256';
import type { Sha256 } from '@/types/sha256';

const envData = getEnvData();
const simpleDeployedEnvName = getSimplifiedDeployedEnvName();
const localBackendCacheTimeInMinutes = parseInt(envData.GRAPHQL_LOCAL_DISKCACHE_TTL_MINUTES || '20');

const graphServerUrl = process.env.NEXT_PUBLIC_GRAPHQL_API_HOST;
const elementApiUrl = process.env.CMS_ELEMENT_API_URL;
const diskCacheEnabled = envData.deployedEnvName.indexOf('local--') === 0 && envData.GRAPHQL_LOCAL_DISKCACHE === 'true';
const isCalledFromBrowser = typeof window !== 'undefined';
const isProd = () => envData.deployedEnvName === 'prod';
const isRunTimeOnServer = () => typeof process !== 'undefined' && process.env.npm_lifecycle_script?.indexOf('next build') === -1;

// Using GET requests means not using POST, which means there will not be caching at the CDN level.
// For now, we only want to use CDN cache (GET requests) on the client side or if: in production + on the server side + at run time (not build time).
// This reduces the amount of stale data that gets returned from GraphQL, limiting it to only when we could potentially bring Graph down with too much traffic.
const useGetRequests = isCalledFromBrowser || (isProd() && isRunTimeOnServer());

if (!graphServerUrl) {
  throw new Error('NEXT_PUBLIC_GRAPHQL_API_HOST environment variable is required.');
}

const mergeAdditionalHeaders = (oldHeaders: object, additionalHeaders: object = {}) => {
  const newHeaders: {
    'x-pbs-kids-cms-element-api-url'?: string;
  } = Object.assign(
    {},
    oldHeaders,
    additionalHeaders,
  );

  if (elementApiUrl) {
    newHeaders['x-pbs-kids-cms-element-api-url'] = elementApiUrl;
  }

  return {
    headers: newHeaders,
  };
};

const clients: Array<{ key: string, client: ApolloClient<object> }> = [];

const getClientIdentity = () => {
  const clientSource = isCalledFromBrowser ? 'browser' : 'server';
  const clientName = `pbs.kids.website.${simpleDeployedEnvName}.${clientSource}`;

  const clientVersion = JSON.stringify({
    semver: envData.packageVersion,
    commit: envData.buildId || 'n/a',
    built: envData.buildTime || 'n/a',
  });

  return {
    clientName,
    clientVersion,
  };
};

const { clientName, clientVersion } = getClientIdentity();

const getClient = async (additionalHeaders: object = {}) => {
  const clientKey = JSON.stringify(additionalHeaders);
  const existingClient = clients.find((client) => client.key === clientKey);
  if (existingClient) {
    return existingClient.client;
  }

  const { createPersistedQueryLink } = await import('@apollo/client/link/persisted-queries');
  const { HttpLink, InMemoryCache } = await import('@apollo/client');
  const { setContext } = await import('@apollo/client/link/context');

  const httpLink = new HttpLink({ uri: graphServerUrl });

  const authLink = setContext((_, { headers }) => {
    return mergeAdditionalHeaders(headers, additionalHeaders);
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  };

  const persistedQueriesLink = createPersistedQueryLink({
    useGETForHashedQueries: useGetRequests,
    sha256: (sha256 as Sha256),
  });

  const clientOptions: ApolloClientOptions<object> = {
    link: persistedQueriesLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
    name: clientName,
    version: clientVersion,
  };

  if (!isCalledFromBrowser) {
    // Disable Apollo Client caching when called on the server side.
    const { VoidCache } = await import('./client-caching');

    clientOptions.cache = new VoidCache();

    clientOptions.defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
  }

  const client = new ApolloClient(clientOptions);

  clients.push({ key: clientKey, client });

  return client;
};

const convertStringToDocNode = async (query: string) => {
  const { gql } = await import('@apollo/client');
  return gql(query);
};

function getGqlString(doc: DocumentNode) {
  return doc.loc && doc.loc.source.body;
}

const queryGraph = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
  onError?: (errors: Array<GraphQLError|Error>) => void,
) => {
  const operation: {
    query: DocumentNode,
    variables?: object,
  } = {
    query: typeof query === 'string' ? await convertStringToDocNode(query) : query,
    variables,
  };
  const now = Date.now();
  const client = await getClient(additionalHeaders);

  return client
    .query(operation)
    .catch(async (response) => {
      const fatalError = new Error(
        `queryGraph() : ${JSON.stringify({ isCalledFromBrowser })} : ` + await getHumanReadableErrorMessage(response, query, variables, additionalHeaders),
      );
      if (typeof onError === 'function') {
        onError([ fatalError ]);
      }
      throw fatalError;
    }).then(async (response) => {
      if (response?.errors?.length) {
        if (typeof onError === 'function') {
          onError(response.errors as Array<GraphQLError>);
        } else {
          logger.debug(
            await getHumanReadableErrorMessage(response, query, variables, additionalHeaders),
          );
        }
      }
      completionMessage(null, now, query, variables, additionalHeaders);
      return response.data;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let diskCacheInstance: any;

const getDiskCacheInstance = async () => {
  if (diskCacheInstance) {
    return diskCacheInstance;
  }
  // Type definitions are not available for ttl-file-cache and
  // I'm not sure a better way than this to suppress the TS errors.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  const DiskCache = await import('ttl-file-cache');

  // This dynamic import is done because a normal import causes issues in the browser.
  // eslint-disable-next-line new-cap
  return diskCacheInstance = new DiskCache.default({
    // default dir is '/tmp/ttl-file-cache' or whatever os.tempdir() resolves to on your operating system
    dir: `/tmp/${envData.packageName}/utils.graphql/ttl-file-cache`,
  });
};

const getQueryCacheKey = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  const queryAsString = typeof query === 'string' ? query : getGqlString(query)?.replace(/\s+/g, ' ');
  return sha256(JSON.stringify({
    queryAsString,
    variables,
    additionalHeaders,
    graphServerUrl,
    elementApiUrl,
  }));
};

const queryGraphWithDiskCache = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
) => {
  const cacheKey = await getQueryCacheKey(query, variables, additionalHeaders);
  const startTime = Date.now();
  const cache = await getDiskCacheInstance();
  const cachedResponse = cache.get(cacheKey);

  if (cachedResponse) {
    completionMessage(true, startTime, query, variables, additionalHeaders);
    return JSON.parse(cachedResponse.toString());
  }

  const response = await queryGraph(query, variables, additionalHeaders);
  completionMessage(false, startTime, query, variables, additionalHeaders);
  await cache.set(cacheKey, JSON.stringify(response), (localBackendCacheTimeInMinutes * 60));
  return response;
};

const initProgressBar = async () => {
  if (typeof window !== 'undefined') {
    return await import('@/utils/progress-bar').then((module) => module.default);
  } else {
    return {
      start: () => {},
      complete: () => {},
    };
  }
};

const queryGraphClient = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
  onError?: (errors: Array<GraphQLError|Error>) => void,
) => {
  if (!isCalledFromBrowser) {
    throw new Error('queryGraphClient should only be called from a client. Use queryGraphServer instead.');
  }

  const progressBar = await initProgressBar();

  progressBar.start();

  const data = await queryGraph(query, variables, additionalHeaders, onError)
    .catch((error) => {
      progressBar.complete();
      const humanReadable = getHumanReadableErrorMessage(error, query, variables, additionalHeaders);
      humanReadable.then((message) => {
        logger.error(`queryGraphClient() : ${message}`);
        throw new Error(error);
      });
    });
  progressBar.complete();
  return data;
};

const queryGraphServer = async (
  query: DocumentNode | string,
  variables?: object,
  additionalHeaders?: object,
  onError?: (errors: Array<GraphQLError|Error>) => void,
  allowDiskCache = true,
) => {
  if (isCalledFromBrowser) {
    throw new Error('queryGraphServer should only be called from the server. Use queryGraphClient instead.');
  }
  return diskCacheEnabled && allowDiskCache ?
    queryGraphWithDiskCache(query, variables, additionalHeaders) :
    queryGraph(query, variables, additionalHeaders);
};

export {
  getGqlString,
  convertStringToDocNode,
  graphServerUrl,
  mergeAdditionalHeaders,
  queryGraphClient,
  queryGraphServer,
};
