import {
  getEnumName,
  isValidLevel,
  PerformanceCheckResultValue,
  PerformanceCheckSpecificTestResult,
  PerformanceCheckTest,
  PerformanceCheckTestFinalResults,
  PerformanceCheckTestResult,
  PerformanceLevelReason,
} from './types';

import { cpuThreadCount } from './client-tests/cpu';
import { lowBattery, reducedMotion, deviceRAM } from './client-tests/device';
import { networkSpeedTest } from './client-tests/network';
import { webGlSupport } from './client-tests/webgl';
import progressBar from '@/utils/progress-bar';

import Logger from '@/utils/logger';
const logger = new Logger({ caller: 'managers.PerformanceCheck.system' });

const performanceTests: Array<PerformanceCheckTest> = [
  lowBattery,
  deviceRAM,
  reducedMotion,
  cpuThreadCount,
  webGlSupport,
  networkSpeedTest,
];

type PerformanceCheckAllResults = (_testSetOverride: Array<string>) => Promise<PerformanceCheckTestFinalResults>;

const getFinalValue = (results: Array<PerformanceCheckSpecificTestResult>) => {
  const defaultValue = {
    name: '[No test results found]',
    result: {
      value: PerformanceCheckResultValue.LEVEL_ONE,
      reason: PerformanceLevelReason.NO_USABLE_RESULT,
    },
  };

  if (results.length === 0) {
    return defaultValue;
  }

  const sortedResults = Object.assign([], results)
    .reverse() // Reverse so that if results are equal, the last test run is reported to be the determining lowest score
    .sort((a: PerformanceCheckTest, b: PerformanceCheckTest) => {
      return (a?.result?.value || 0) - (b?.result?.value || 0);
    })
    .filter((test: PerformanceCheckTest) => (test?.result?.value ?? 0) > 0);

  return sortedResults?.[0] ?? defaultValue;
};

const runTestSet = async (
  testSet: PerformanceCheckTest,
  floorResult?: PerformanceCheckSpecificTestResult,
) => {
  let reason = 'No reason found';
  let value = PerformanceCheckResultValue.DID_NOT_RUN;
  let timestamp = Date.now();
  const testStart = performance.now();

  if (floorResult) {
    reason = `${floorResult.name} :: ${floorResult.result.reason}`;
  } else {
    let index = 0;
    for (const testMethod of testSet.methods) {
      const methodDescription = `${testSet.name} :: ${testMethod.description || index++}`;
      try {
        if (testSet.skip) {
          throw new Error('Configured to skip test set');
        }
        if (testMethod.skip) {
          throw new Error('Configured to skip method');
        }
        logger.debug(`Running test method \`${methodDescription}\`...`);
        const result: PerformanceCheckTestResult = await testMethod.method();

        if (typeof result?.value !== 'undefined' && isValidLevel(result?.value)) {
          value = result.value;
          reason = result?.reason;

          if (result.timestamp) {
            timestamp = result.timestamp;
          }
          break;
        } else {
          value = PerformanceCheckResultValue.INCONCLUSIVE;
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        reason = error?.message ? 'Error: ' + error?.message : 'Unknown Error';
        value = PerformanceCheckResultValue.INCONCLUSIVE;
        logger.debug('Test method error thrown.', {
          methodDescription,
          error,
          value,
          reason,
        });
      }
    }
  }

  return {
    value,
    reason,
    timestamp,
    duration: parseFloat((performance.now() - testStart).toFixed(2)),
  };
};

const runAllTests: PerformanceCheckAllResults = async (testSetOverride: Array<string>) => {
  const start = performance.now();
  progressBar.start();

  const individualResults: Array<PerformanceCheckSpecificTestResult> = [];

  for (const test of performanceTests) {
    const floorResult: PerformanceCheckSpecificTestResult | undefined = individualResults.find((individualTest) =>
      individualTest.result.value === PerformanceCheckResultValue.LEVEL_ONE,
    );

    const skipDueToOverride = testSetOverride.length > 0 && !testSetOverride.includes(test.name);

    if (floorResult || skipDueToOverride) {
      const skipReason = skipDueToOverride ?
        `Override set to \`${testSetOverride.join(',')}\`` :
        `A previous result already found bottleneck of level ${PerformanceCheckResultValue.LEVEL_ONE}`;

      logger.info(
        `Skipping performance test \`${test.name}\` : ${skipReason}`,
      );
      individualResults.push({
        name: test.name,
        result: {
          value: PerformanceCheckResultValue.DID_NOT_RUN,
          reason: skipReason,
          timestamp: Date.now(),
        },
      });
    } else {
      individualResults.push({
        name: test.name,
        result: await runTestSet(test, floorResult),
      });
    }
  }

  const overallResult = getFinalValue(individualResults);

  progressBar.complete();

  const duration = performance.now() - start;

  return {
    individualResults,
    level: overallResult.result.value,
    reason: `${overallResult.name} :: ${overallResult.result.reason}`,
    duration,
  };
};

const formatDateTime = (timestamp: number) => {
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    dateStyle: 'full',
    timeStyle: 'long',
    hour12: false,
  }).format(timestamp);
};

const makeResultsMoreLegible = (results: Array<PerformanceCheckSpecificTestResult>) => {
  return results.map((test) => {
    const value = test?.result?.value;
    return {
      ...test,
      result: Object.assign(
        {},
        test.result,
        {
          value: value ? `${getEnumName(value)} (${JSON.stringify(value)})` : null,
          timestamp: test?.result?.timestamp ? formatDateTime(test?.result?.timestamp) : null,
        },
      ),
    };
  });
};

export {
  runAllTests,
  makeResultsMoreLegible,
};
