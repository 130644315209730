import { getEnvData, getSimplifiedDeployedEnvName } from '@/utils/env';

const envData = getEnvData();

// Sentry documentation for the "release" and "environment" options:
// https://docs.sentry.io/platforms/javascript/configuration/releases/
// https://docs.sentry.io/platforms/javascript/configuration/environments/

// On the frontend, we want to be as specific as possible with the environment name,
// since JavaScript errors on the client side can be affected by almost anything.
const getFriendlyEnvNameFromDomain = (domainName: string) => {
  if (
    domainName.includes('pbs-kids-website.prod.') ||
    domainName.includes('main.dkhcis20jaz0c')
  ) {
    return 'pbs-kids-website.prod';
  } else if (
    domainName.includes('pbs-kids-website.preprod.') ||
    domainName.includes('main.d2iktlrmh9f3mc')
  ) {
    return 'pbs-kids-website.preprod';
  } else if (
    domainName.includes('release-')
  ) {
    return 'release-*.preprod';
  } else if (
    domainName.includes('amplifyapp.com')
  ) {
    return '*.amplifyapp.com';
  } else if (domainName.includes('ngrok')) {
    return 'ngrok';
  } else if (domainName.includes('localhost')) {
    return 'localhost';
  } else {
    // Includes the CDNs pbskids.org and stage.pbskids.org
    return domainName;
  }
};

const getReleaseString = () => {
  // If modified, also update in ./next.config.js
  // https://docs.sentry.io/platforms/javascript/configuration/releases/#bind-the-version
  return `${envData.packageName}@${envData.packageVersion}+${envData.buildId}`;
};

const SENTRY_PUBLIC_KEY = '5cc66c8b615a90e93cf0763a37e9e3ec';
const SENTRY_PROJECT_ID = '4506864492281856';
const SENTRY_ORG_ID = '260369';
const sentryBaseEndpoint = `https://${SENTRY_PUBLIC_KEY}@o${SENTRY_ORG_ID}.ingest.us.sentry.io`;
const sentryDsn = `${sentryBaseEndpoint}/${SENTRY_PROJECT_ID}`;

const getSentrySampleRate = (caller: 'client' | 'server'): number => {
  if (caller === 'client' && process.env.NEXT_PUBLIC_SENTRY_CLIENT_SAMPLE_RATE) {
    return parseFloat(process.env.NEXT_PUBLIC_SENTRY_CLIENT_SAMPLE_RATE);
  } else if (caller === 'server' && process.env.NEXT_PUBLIC_SENTRY_SERVER_SAMPLE_RATE) {
    return parseFloat(process.env.NEXT_PUBLIC_SENTRY_SERVER_SAMPLE_RATE);
  }
  return 1;
};

const isRunningInBrowser = typeof window !== 'undefined';

const isSentryEnabled = () => {
  const sampleRate = getSentrySampleRate(isRunningInBrowser ? 'client' : 'server');
  return envData.sentryEnabled && sampleRate > 0;
};

const isError = (arg: unknown): boolean => {
  if (arg instanceof Error) {
    return true;
  }
  const stringified = Object.prototype.toString.call(arg);
  if (stringified.includes('Error')) {
    return true;
  }
  return false;
};

const sendLogMessageToSentry = async (caller: string, messageType: 'error' | 'warn' | 'info', date: Date, args: unknown[] = []) => {
  if (isSentryEnabled()) {
    const Sentry = await import('@sentry/nextjs');

    const timestamp = date.toLocaleDateString('en-US', {
      second: 'numeric',
      minute: 'numeric',
      hour: 'numeric',
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour12: false,
      timeZone: 'America/New_York',
      timeZoneName: 'short',
    });

    // Docs about grouping: https://docs.sentry.io/concepts/data-management/event-grouping/#default-error-grouping-algorithms
    const postamble = `[ utils.sentry | ${isRunningInBrowser ? 'frontend' : 'backend'} ] [ ${caller} :: ${messageType} @ ${timestamp} (${performance.now()}) ]`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const argsWithDetails = args.map((arg: any) => {
      if (isError(arg)) {
        return [
          `Error message: ${arg?.message || null}`,
          `Stack trace:\n${arg?.stack || null}`,
          `Full value: ${JSON.stringify(arg, null, 2)}`,
        ].join('\n');
      } else {
        return `${typeof arg === 'string' ? arg : JSON.stringify(arg, null, 2)}`;
      }
    }).join('\n\n');

    const fullMessage = `${argsWithDetails}\n\n${postamble}`;
    const severity = messageType === 'error' ? 'error' : messageType === 'warn' ? 'warning' : 'info';

    Sentry.captureMessage(fullMessage, severity);
  }
};

export {
  getFriendlyEnvNameFromDomain,
  getReleaseString,
  getSentrySampleRate,
  getSimplifiedDeployedEnvName,
  isSentryEnabled,
  sendLogMessageToSentry,
  sentryBaseEndpoint,
  sentryDsn,
};
