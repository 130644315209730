import React from 'react';

interface Props {
  /**
   * Using `any` type to avoid conflicts with @svgr/webpack plugin or babel-plugin-inline-react-svg plugin.
   * This is recommended by [NextJS Image Types](./node_modules/next/image-types/global.d.ts).
   */
  icon?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  classList?: string
}

const StyledIcon: React.FC<Props> = ({
  icon,
  classList,
}) => {
  const styles = classList || '';
  const Icon = icon;

  return (
    <Icon aria-hidden='true' className={styles} />
  );
};

export default StyledIcon;
