/* eslint-disable @typescript-eslint/no-explicit-any */

const isUndefined = (obj: any, nullIsUndefined = false) =>
  typeof obj === 'undefined' || (nullIsUndefined && obj === null);

const deepValue = (target: any, path: string) => {
  return path.split('.').reduce((field, part) => {
    if (
      field !== null &&
      !isUndefined(field) &&
      !isUndefined(field[part])
    ) {
      return field[part];
    }
  }, target);
};

export {
  deepValue,
};
