import { ReactNode, useContext, useEffect, useState } from 'react';
import GlobalStateContext from '@/components/global/GlobalState';
import ThemeStyles from './index';

export default function ThemeStylesAppLevel() {
  const { showThemeDevUI } = useContext(GlobalStateContext);
  const [ debuggerComponent, setDebuggerComponent ] = useState<ReactNode|null>(null);

  useEffect(() => {
    if (showThemeDevUI) {
      // Dynamically imported so users don't have to download this code unless they need it.
      import('@/components/private/ThemeDebugger/ThemeDebuggerWrapper')
        .then((component) => {
          const ThemeDebuggerWrapper = component.default;

          setDebuggerComponent( <ThemeDebuggerWrapper /> );
        });
    }
  }, [
    setDebuggerComponent,
    showThemeDevUI,
  ]);

  return <>
    {/*
      `themeStyleType='globalDefault'` provides the default hardcoded theme on every page. If a page needs to override these,
      they can do so by passing their own `resolvedPageThemes` this component within a page directly.
    */}
    <ThemeStyles themeStyleType='globalDefault' />

    {debuggerComponent}
  </>;
}
