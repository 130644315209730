// Sourced from: https://stackoverflow.com/a/5624139
type RGB = {
  r: number,
  g: number,
  b: number,
};

const expandShorthandHex = (hex: string): string => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  return hex.replace(shorthandRegex, (m, r, g, b) => {
    return '#' + r + r + g + g + b + b;
  });
};

const hexToRgb = (hex: string): RGB | null => {
  hex = expandShorthandHex(hex);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

const hexToRgbAsString = (hex: string): string => {
  const object = hexToRgb(hex);
  if (object) {
    return `${object.r} ${object.g} ${object.b}`;
  }
  return '';
};

module.exports = { expandShorthandHex, hexToRgb, hexToRgbAsString };
