const isBrowser = (type: string, method: string, name?: string, value?: string | object | number | boolean) => {
  if (typeof window === 'undefined') {
    void(`${type} attempting to ${method} name: ${name} value: ${value?.toString ? value.toString() : value}`);
  } else {
    return true;
  }
};

const queryStringWrapper = {
  get: (name: string) => {
    if (isBrowser('queryStringWrapper', 'get', name)) {
      return new URLSearchParams(window.location.search).get(name);
    }
  },
  set: (name: string, value: string) => {
    if (isBrowser('queryStringWrapper', 'set', name, value)) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.set(name, value);

      const protocol = window.location.protocol;
      const search = '?' + decodeURIComponent(urlSearchParams.toString());

      const state = {};
      const url = `${protocol}//${window.location.host}${window.location.pathname}${search}`;

      history.pushState(state, '', url);
    }
  },
};

const localStorageWrapper = {
  get: (name: string) => {
    if (isBrowser('localStorageWrapper', 'get', name)) {
      const value = localStorage.getItem(name);
      try {
        return JSON.parse(value as string);
      } catch {
        return value;
      }
    }
  },
  set: (name: string, value: object | number | string | boolean) => {
    if (isBrowser('localStorageWrapper', 'set', name, value)) {
      const oldValue = localStorage.getItem(name);
      const newValue = JSON.stringify(value);
      if (oldValue !== newValue) {
        localStorage.setItem(name, newValue);
      }
    }
  },
  remove: (name: string) => {
    if (isBrowser('localStorageWrapper', 'remove', name)) {
      localStorage.removeItem(name);
    }
  },
  getKeys: () => {
    if (isBrowser('localStorageWrapper', 'getKeys')) {
      return Object.keys(localStorage);
    }
  },
};

const sessionStorageWrapper = {
  get: (name: string) => {
    if (isBrowser('sessionStorageWrapper', 'get', name)) {
      const value = sessionStorage.getItem(name);
      try {
        return JSON.parse(value as string);
      } catch {
        return value;
      }
    }
  },
  set: (name: string, value: object | number | boolean | string) => {
    if (isBrowser('sessionStorageWrapper', 'set', name, value)) {
      sessionStorage.setItem(name, JSON.stringify(value));
    }
  },
  remove: (name: string) => {
    if (isBrowser('sessionStorageWrapper', 'remove', name)) {
      sessionStorage.removeItem(name);
    }
  },
  getKeys: () => {
    if (isBrowser('sessionStorageWrapper', 'getKeys')) {
      return Object.keys(sessionStorage);
    }
  },
};

const createExpiringStorage = (storage: Storage, namespace = '') => {
  const getKeyName = (name: string) => namespace + '.' + name;

  const get = (name: string) => {
    if (isBrowser('createExpiringStorage', 'get', name)) {
      const value = storage.getItem(getKeyName(name));
      try {
        const parsed = JSON.parse(value as string);
        if (!parsed.exp || (new Date().getTime() < new Date(parsed.exp).getTime())) {
          return parsed?.val;
        }
      } catch {
        return;
      }
    }
  };

  const set = (name: string, value: object | number | string | boolean, ttlSeconds?: number) => {
    if (isBrowser('createExpiringStorage', 'set', name, value)) {
      const expiresAt = ttlSeconds ? new Date().getTime() + (ttlSeconds * 1000) : undefined;

      storage.setItem(getKeyName(name), JSON.stringify({
        exp: expiresAt,
        val: value,
      }));
    }
  };

  const remove = (name: string) => {
    if (isBrowser('createExpiringStorage', 'removeItem', name)) {
      storage.removeItem(getKeyName(name));
    }
  };

  const getKeys = () => {
    return Object.keys(storage).filter((key) => key.startsWith(namespace));
  };

  return {
    get,
    set,
    remove,
    getKeys,
  };
};

export {
  queryStringWrapper,
  localStorageWrapper,
  sessionStorageWrapper,
  createExpiringStorage,
};
