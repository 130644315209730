import { PerformanceCheckResultValue, PerformanceCheckTest } from '../types';

const cpuThreadCount: PerformanceCheckTest = {
  name: 'CPU Thread Count',
  methods: [
    {
      description: 'navigator.hardwareConcurrency',
      method: async () => {
        let reason = 'navigator.hardwareConcurrency > 4';
        let value = PerformanceCheckResultValue.LEVEL_THREE;

        if (typeof navigator?.hardwareConcurrency !== 'number') {
          throw new Error('Browser does not support Navigator API: hardwareConcurrency');
        }

        if (navigator.hardwareConcurrency <= 4) {
          reason = 'navigator.hardwareConcurrency = ' + navigator?.hardwareConcurrency;
          value = PerformanceCheckResultValue.LEVEL_TWO;
        }

        return {
          value,
          reason,
        };
      },
    },
  ],
};

export {
  cpuThreadCount,
};
