import { ReactNode, useContext, useEffect, useState } from 'react';

import { ResolvedPageThemes, getHardcodedDefaultTheme, ThemeStyleType } from '@/utils/theming-system';

import GlobalStateContext from '@/components/global/GlobalState';

type Props = {
  resolvedPageThemes?: ResolvedPageThemes
  themeStyleType?: ThemeStyleType,
};

export default function ThemeStyles({ resolvedPageThemes, themeStyleType = 'page' }: Props) {
  const { showThemeDevUI } = useContext(GlobalStateContext);

  if (!resolvedPageThemes) {
    resolvedPageThemes = getHardcodedDefaultTheme(themeStyleType);
  }

  const [ debuggerComponent, setDebuggerComponent ] = useState<ReactNode|null>(null);

  useEffect(() => {
    if (showThemeDevUI && resolvedPageThemes && !debuggerComponent) {
      // Dynamically imported so users don't have to download this code unless they need it.
      import('@/components/private/ThemeDebugger')
        .then((component) => {
          if (resolvedPageThemes) {
            const ThemeDebugger = component.default;

            setDebuggerComponent(
              <ThemeDebugger
                themeStyleType={themeStyleType}
                resolvedPageThemes={resolvedPageThemes}
              />,
            );
          }
        });
    }
  }, [
    debuggerComponent,
    resolvedPageThemes,
    setDebuggerComponent,
    showThemeDevUI,
    themeStyleType,
  ]);

  if (debuggerComponent) {
    return <>
      <div style={{ position: 'absolute', top: '-999999rem' }}>
        {debuggerComponent}
      </div>
    </>;
  }

  return <>
    <style
      data-pbsk-theme-styles-source='normal'
      dangerouslySetInnerHTML={{
        __html: '\n' + resolvedPageThemes.css + '\n',
      }}
    />
  </>;
}
