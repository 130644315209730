import envData from '../.pre-build/env.json';

const getEnvData = () => {
  return envData;
};

// On the backend, it is difficult to get as specific, since the same code runs on multiple environments.
// For example, stage.pbskids.org is just a proxy to our prod Amplify instance,
// so we can't differentiate between the two on the backend.
const getSimplifiedDeployedEnvName = () => {
  const { deployedEnvName } = envData;

  if (deployedEnvName.includes('local-')) {
    return 'localhost';
  } else if (deployedEnvName.includes('amplify-branch-')) {
    return 'amplify';
  } else {
    // Should always be 'prod' or 'preprod'
    return deployedEnvName;
  }
};

export {
  getEnvData,
  getSimplifiedDeployedEnvName,
};
