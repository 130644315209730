import '@/styles/globals.scss';
import '@/utils/polyfills';
import styles from './_app.module.scss';
import { fontClasses } from '@/components/global/Fonts';
import { GlobalStateProvider } from '@/components/global/GlobalState';
import Head, { PageMetaDefaults } from '@/components/global/Head';
import SiteFooter from '@/components/global/SiteFooter';
import ThemeStylesAppLevel from '@/components/global/ThemeStyles/ThemeStylesAppLevel';
import TrackingIncludes from '@/components/global/TrackingIncludes';
import PageInfo from '@/components/private/PageInfo';
import PerformanceCheck from '@/components/private/PerformanceCheck';
import { StationLocalizationProvider } from '@/contexts/StationLocalizationContext';
import progressBar from '@/utils/progress-bar';
import type { AppProps } from 'next/app';

progressBar.handleRouterEvents();

function PageBackgroundLayer() {
  return <>
    <div className={styles.pageBackgroundLayer}>
      <div className={styles.solidColor} />
      <div className={styles.image} />
      <div className={styles.gradientOverlay} />
    </div>
  </>;
}

export default function App({ Component, pageProps }: AppProps) {
  if (pageProps.errorPage) {
    return <div
      className={`${styles.rootWrapper} ${fontClasses.join(' ')}`}
    >
      <Head
        pageDescription={ PageMetaDefaults.DEFAULT_PAGE_DESCRIPTION }
        pageImageUrl={ PageMetaDefaults.DEFAULT_PAGE_IMAGE }
      />

      <div className={styles.mainLayout}>
        <Component {...pageProps} />

        <PageBackgroundLayer />
      </div>
    </div>;
  } else {
    return <div
      className={`${styles.rootWrapper} ${fontClasses.join(' ')}`}
      data-has-masthead-content={pageProps.hasMastheadContent ? 'true' : 'false'}
      data-has-masthead-theme={pageProps?.resolvedPageThemes?.hasMastheadTheme ? 'true' : 'false'}
    >
      <GlobalStateProvider>
        <Head
          pageDescription={ PageMetaDefaults.DEFAULT_PAGE_DESCRIPTION }
          pageImageUrl={ PageMetaDefaults.DEFAULT_PAGE_IMAGE }
        />
        <TrackingIncludes />
        <PerformanceCheck />

        { process.env.NEXT_PUBLIC_ENABLE_PAGE_INFO === 'true' && <PageInfo
          pageData={pageProps?.pageData || pageProps?.collectionData || pageProps?.videoData}
        /> }

        <ThemeStylesAppLevel />

        <StationLocalizationProvider>
          <div className={styles.mainLayout}>
            <Component {...pageProps} />
            {!pageProps.hideFooter && <SiteFooter />}

            <PageBackgroundLayer />
          </div>
        </StationLocalizationProvider>

      </GlobalStateProvider>
    </div>;
  }
}
