import CloseButton from '@/components/base/CloseButton';
import PointerEventOutside from '@/components/base/PointerEventOutside';
import DialogPanel from '@/components/base/DialogPanel';
import React, { ReactNode, useEffect, useState, MutableRefObject, useCallback } from 'react';
import styles from './Modal.module.scss';

interface Props {
  children: ReactNode,
  dialogLabel?: string,
  useAnimation?: boolean,
  modalOverrideClass?: string,
  onClose?: () => void,
  onOpen?: () => void,
  overlayOverrideClass?: string,
  toggleButtonRef?: MutableRefObject<HTMLButtonElement|null>,
}

const Modal: React.FC<Props> =({
  children,
  dialogLabel,
  useAnimation = true,
  modalOverrideClass = '',
  onClose,
  onOpen,
  overlayOverrideClass = '',
  toggleButtonRef,
}) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isShowing, setIsShowing ] = useState(false);
  const screenSizeSmall = 568;

  const setOverflowY = useCallback(() => {
    document.body.classList.toggle(
      'overflow-y-hidden',
      window.innerWidth <= screenSizeSmall && isModalOpen,
    );
  }, [ isModalOpen ]);

  const handleClose = useCallback(() => {
    if (isModalOpen) {
      setIsShowing(false);
      setIsModalOpen(false);
      onClose?.();

      toggleButtonRef?.current?.focus();
    }
  }, [ isModalOpen, onClose, toggleButtonRef ]);

  const handleEsc = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  }, [ handleClose ]);

  useEffect(() => {
    const toggleButtonCurrent = toggleButtonRef?.current;

    setOverflowY();

    window.addEventListener('resize', setOverflowY);
    window.addEventListener('keydown', handleEsc);

    const onToggleButtonClick = (e: Event) => {
      const newState = !isModalOpen;
      e.preventDefault();
      setIsModalOpen(newState);

      if (isModalOpen) {
        onClose?.();
      } else {
        const open = () => {
          onOpen?.();
          setIsShowing(newState);
        };
        if (useAnimation) {
          setTimeout(open, 1);
        } else {
          open();
        }
      }
    };

    if (toggleButtonCurrent?.addEventListener) {
      toggleButtonCurrent?.addEventListener('click', onToggleButtonClick);
    }

    return () => {
      window.removeEventListener('resize', setOverflowY);
      window.removeEventListener('keydown', handleEsc);

      if (toggleButtonCurrent?.removeEventListener) {
        toggleButtonCurrent?.removeEventListener('click', onToggleButtonClick);
      }
    };
  }, [
    handleClose,
    handleEsc,
    isModalOpen,
    isShowing,
    onClose,
    onOpen,
    setOverflowY,
    toggleButtonRef,
    useAnimation,
  ]);

  return isModalOpen && <>
    <div className={`${styles.modalOverlay} ${overlayOverrideClass}`}/>

    <PointerEventOutside
      inputEvents={[
        { inputEventName: 'click', handler: (e) => {
          const clickFromToggleButton = e?.target === toggleButtonRef?.current;
          if (!clickFromToggleButton && isModalOpen) {
            handleClose();
          }
        } },
      ]}
    >
      <div className={`${styles.modalBody} ${modalOverrideClass} ${isShowing ? styles.showModal : ''} ${useAnimation ? styles.useAnimation : ''}`}>
        <DialogPanel label={dialogLabel}>
          <CloseButton className={styles.closeBtn} handleClick={handleClose} />
          { children }
        </DialogPanel>
      </div>
    </PointerEventOutside>
  </>;
};

export default Modal;
