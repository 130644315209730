import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './PageInfo.module.scss';
import CloseButton from '@/components/base/CloseButton';
import GlobalStateContext from '@/components/global/GlobalState';
import { formatTimestamp } from '@/managers/VideoPlayer/duration';
import { PbsKidsVideo, PbsKidsVideoPlaylist, PbsKidsVideosWebPage } from '@/types/pbskids-graph';

enum PanelState {
  closed = 'PANEL_CLOSED',
  open = 'PANEL_OPEN',
}

type PageInfoProps = {
  pageData?: PbsKidsVideosWebPage | PbsKidsVideoPlaylist | PbsKidsVideo,
};

export default function PageInfo({ pageData }: PageInfoProps) {
  const [ panelState, setPanelState ] = useState(PanelState.closed);
  const [ pageCacheMessage, setPageCacheMessage ] = useState('N/A');
  const { showDevUI, setShowDevUI, showThemeDevUI, setShowThemeDevUI } = useContext(GlobalStateContext);
  const timeZone = (new Intl.DateTimeFormat()).resolvedOptions().timeZone;
  const nowSec = useMemo(() => Math.round(Date.now() / 1000), []);

  const openPageInfoPanel = () => {
    setPanelState(PanelState.open);
  };

  const closePageInfoPanel = () => {
    setPanelState(PanelState.closed);
  };

  const toggleShowPerformanceCheck = (e: ChangeEvent) => {
    setShowDevUI((e.target as HTMLInputElement)?.checked);
  };

  const toggleShowThemeDebugger = (e: ChangeEvent) => {
    setShowThemeDevUI((e.target as HTMLInputElement)?.checked);
  };

  const appendMessageLine = ( message: string | null, label = '', title = '') => {
    return message ? `<dd ${title ? 'title="' + title + '"' : ''}>${label ? label + ': ' : ''}${message};</dd>`: '';
  };

  const getFriendlyDate = useCallback((timestampInSec?: string | null): string => {
    return !timestampInSec ? 'N/A' :
      new Date(parseInt(timestampInSec) * 1000).toLocaleString() + ` (${timeZone})`;
  }, [ timeZone ]);

  const getAge = (timestampInSec?: string | null): string => {
    return !timestampInSec ? 'N/A' :
      (nowSec - parseInt(timestampInSec)).toString();
  };

  const cmsCacheDate = getFriendlyDate(pageData?._meta?.cacheDetails?.cmsRenderedTimestamp);
  const cmsCacheAge = getAge(pageData?._meta?.cacheDetails?.cmsRenderedTimestamp);
  const proxyCacheDate = getFriendlyDate(pageData?._meta?.cacheDetails?.proxyCacheTimestamp);
  const proxyCacheAge = getAge(pageData?._meta?.cacheDetails?.proxyCacheTimestamp);

  useEffect(() => {
    // Fetch the current page to read the headers on it since we can't
    // read the headers on the page we're loaded on via client side JS.
    fetch(window.location.href)
      .then((res) => {
        const age = res.headers.get('age') || '0';
        const ageDate = getFriendlyDate((nowSec - parseInt(age)).toString());
        const cacheControl = res.headers.get('cache-control');
        const xCache = res.headers.get('x-cache');

        let cacheMessage = appendMessageLine(formatTimestamp(age), 'Age', ageDate);
        cacheMessage = cacheMessage + appendMessageLine(age, 'Age (sec)', ageDate);
        cacheMessage = cacheMessage + appendMessageLine(xCache);
        cacheMessage = cacheMessage + appendMessageLine(cacheControl, 'Status');

        setPageCacheMessage(cacheMessage);
      });
  }, [ getFriendlyDate, nowSec ]);

  return <section className={
    `${styles.pageInfoTab} ${panelState === PanelState.open ? styles.open : '' }`
  }>
    <header>
      <h2 className={styles.title} onClick={openPageInfoPanel}>
        { panelState === PanelState.open && 'Page '}
        Info
      </h2>

      { panelState === PanelState.open && <CloseButton
        handleClick={closePageInfoPanel}
        className={styles.closeButton}
      /> }
    </header>

    {panelState === PanelState.open && <dl className={styles.body}>
      <dt>Build Info</dt>
      <dd>Version: {process.env.packageVersion}</dd>
      <dd>Build ID: {process.env.buildId || 'N/A'}</dd>
      <dd>Build Time: {process.env.buildTime || 'N/A'}</dd>

      <hr/>

      <dt>Page (HTML) Cache</dt>
      <div dangerouslySetInnerHTML={{ __html: pageCacheMessage }}></div>

      <hr/>

      <dt>Data (GraphQL/CMS) Cache</dt>
      { !pageData?._meta?.cacheDetails ? <dd>N/A</dd> : <>
        <dd title={ cmsCacheDate }>CMS Cache Age: { formatTimestamp(cmsCacheAge) }</dd>
        <dd title={ cmsCacheDate }>CMS Cache Age (sec): { cmsCacheAge }</dd>
        <br/>
        <dd title={ proxyCacheDate }>Proxy Cache Age: { formatTimestamp(proxyCacheAge) }</dd>
        <dd title={ proxyCacheDate }>Proxy Cache Age (sec): { proxyCacheAge }</dd>
        <dd>Proxy Cache Status: { pageData._meta.cacheDetails.proxyCacheStatus }</dd>
      </> }

      <hr/>

      <dt>Dev Tools</dt>
      <dd>Performance Check: <input type='checkbox' onChange={toggleShowPerformanceCheck} checked={showDevUI}></input></dd>
      <dd>Theme Debugger: <input type='checkbox' onChange={toggleShowThemeDebugger} checked={showThemeDevUI}></input></dd>
    </dl>}
  </section>;
}
