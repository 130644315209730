import React, { useRef, useEffect } from 'react';
import { InputEvents } from '@/types/inputEvents';
// Component for detecting pointer events outside of a given element and acting on them
// Based on this StackOverflow answer: https://stackoverflow.com/a/42234988

type Props = {
  children: React.ReactNode,
  classList?: string,
  inputEvents: InputEvents,
};

const useOutsideHandlers = (
  ref: React.RefObject<HTMLDivElement>,
  inputEvents: InputEvents,
) => {
  useEffect(() => {
    const handlers: InputEvents = [];

    for (const { handler, inputEventName } of inputEvents) {
      const handleEvent = (event: MouseEvent | TouchEvent) => {
        // Fix bug where elements which have been removed from the DOM are falsely considered "outside" the component.
        if ((event?.target as HTMLElement)?.isConnected === false) {
          return;
        }
        if (ref.current && !ref.current.contains(event.target as Node)) {
          handler(event);
        }
      };
      document.addEventListener(inputEventName, handleEvent);
      handlers.push({
        handler: handleEvent,
        inputEventName: inputEventName,
      });
    }
    return () => {
      for (const { handler, inputEventName } of handlers) {
        document.removeEventListener(inputEventName, handler);
      }
    };
  }, [
    ref,
    inputEvents,
  ]);
};

export default function PointerEventOutside({ children, classList, inputEvents }: Props) {
  const wrapperRef = useRef(null);

  useOutsideHandlers(wrapperRef, inputEvents);

  return <div ref={wrapperRef} className={classList}>
    {children}
  </div>;
}
