/*
  This code is copied straight out of the NPM package `crypto-hash`. For some reason when
  used in conjunction with App Router, the build fails with a mysterious error like this:

  ```
  Failed to compile.

  static/media/thread.2465a67c.js from Terser
    x 'import', and 'export' cannot be used outside of module code
    ,-[1:1]
  1 | import { Buffer } from "node:buffer";
    : ^^^^^^
  2 | import crypto from "node:crypto";
  3 | import { parentPort } from "node:worker_threads";
  4 | parentPort.on("message", (message)=>{
    `----

  Caused by:
      0: failed to parse input file
      1: Syntax Error
  Error:
    x 'import', and 'export' cannot be used outside of module code
    ,-[1:1]
  1 | import { Buffer } from "node:buffer";
    : ^^^^^^
  2 | import crypto from "node:crypto";
  3 | import { parentPort } from "node:worker_threads";
  4 | parentPort.on("message", (message)=>{
    `----

  Caused by:
      0: failed to parse input file
      1: Syntax Error

  > Build failed because of webpack errors
    Creating an optimized production build  .
  ```

  Including only the barebones function we need here is a workaround. No idea why it works.
*/

/* eslint-env browser */

const bufferToHex = (buffer) => {
  const view = new DataView(buffer);

  let hexCodes = '';
  for (let index = 0; index < view.byteLength; index += 4) {
    hexCodes += view.getUint32(index).toString(16).padStart(8, '0');
  }

  return hexCodes;
};

const create = (algorithm) => async (buffer, { outputFormat = 'hex' } = {}) => {
  if (typeof buffer === 'string') {
    buffer = new TextEncoder().encode(buffer);
  }

  const hash = await crypto.subtle.digest(algorithm, buffer);

  return outputFormat === 'hex' ? bufferToHex(hash) : hash;
};

export const sha256 = create('SHA-256');
