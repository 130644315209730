import React, { useContext } from 'react';

import { StationLogoTheme } from '@/types/stations';
import BridgeLink from '@/components/base/BridgeLink';
import Image from 'next/image';
import LanguageLocalizer from '@/utils/language-localization';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';

type Props = {
  appendElement?: React.ReactNode
  innerClasslist?: string
  logoTheme: StationLogoTheme
  prependElement?: React.ReactNode
  sizes?: string
  wrapperClasslist?: string
};

export default function LocalStationLogo({
  appendElement,
  prependElement,
  wrapperClasslist,
  innerClasslist,
  logoTheme,
  sizes,
}: Props) {
  const { __t } = new LanguageLocalizer( useContext, 'components/global/LocalStationLogo' );
  const { station } = useContext(StationLocalizationContext);
  const logoUrl = station.logos[logoTheme];
  const logoLink = station.siteUrl;

  const getImage = (src: string, sizes?: string) => {
    return <>
      <Image
        src={src}
        sizes={sizes || '100vw'}
        alt={__t('stationLogoTitleText', { name: station.commonName })}
        width='300'
        height='300'
      />
    </>;
  };

  return logoUrl ? <>
    <span className={wrapperClasslist}>
      {logoUrl && <>
        {prependElement || ''}
        {logoLink ?
          <div>
            <BridgeLink
              className={innerClasslist}
              clickAnimation={true}
              href={logoLink}
              title={`${station.commonName} website.`}
            >
              {getImage(logoUrl, sizes)}
            </BridgeLink>
          </div> :
          <span className={innerClasslist}>
            {getImage(logoUrl, sizes)}
          </span>
        }
        {appendElement || ''}
      </>}
    </span>
  </> : <></>;
}
