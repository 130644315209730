import { StationLogoTheme } from '@/types/stations';
import BridgeLink from '@/components/base/BridgeLink';
import LanguageLocalizer from '@/utils/language-localization';
import LocalStationLogo from '@/components/global/LocalStationLogo';
import Link from 'next/link';
import React, { useContext } from 'react';
import StationSettings from '@/components/modules/StationSettings';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import styles from './SiteFooter.module.scss';

export default function SiteFooter() {
  const { __t } = new LanguageLocalizer( useContext, 'components/global/SiteFooter' );
  const { station } = useContext(StationLocalizationContext);
  return <>
    <footer className={styles.mainContainer}>
      <ul className={styles.links}>
        <li><Link href={'/grownups/'}>{__t('forParents')}</Link></li>
        <li><Link href='https://www.pbs.org/parents/newsletter'>{__t('weeklyNewsletter')}</Link></li>
        <li><Link href={'/apps/'}>{__t('apps')}</Link></li>
        <li><Link href={'/help/'}>{__t('help')}</Link></li>
        {station.tvssUrl && <li>
          <BridgeLink
            href={station.tvssUrl}
            title={`${station.commonName} TV schedule`}
          >
            {__t('tvTimes')}
          </BridgeLink>
        </li>}
      </ul>

      <LocalStationLogo
        logoTheme={StationLogoTheme.White} // Hardcoded since the footer is not themed currently.
        prependElement={
          <p>{__t('from')}</p>
        }
        wrapperClasslist={styles.logoWrapper}
        innerClasslist={styles.stationLogo}
        sizes='300px'
      />

      <div className={styles.bottomWrapper}>
        <ul className={styles.bottomLinkGrid}>
          <li><StationSettings wrapperClass={styles.stationSettingsWrapper} /></li>
          <li><Link href={'/privacy/'}>{__t('privacyPolicy')}</Link></li>
          <li><Link href={'/privacy/termsofuse.html'}>{__t('termsOfUse')}</Link></li>
        </ul>
        <span className={styles.copyright}>pbskids.org &copy; {new Date().getFullYear()}</span>
      </div>
    </footer>
  </>;
}
