/* eslint-disable no-console, @typescript-eslint/no-explicit-any */

import { getEnvData } from '@/utils/env';
const envData = getEnvData();

const isBrowser = typeof window !== 'undefined';
const isDeployedToAWS = !isBrowser && envData.AWS_BRANCH;
const lightMode = isBrowser && !window.matchMedia('(prefers-color-scheme: dark)').matches;

const colorMap: any = {
  debug: 'darkgray',
  info: 'green',
  log: 'cyan',
  warn: 'yellow',
  error: 'red',
  table: 'magenta',
};

const clashWithLightMode = [
  'yellow',
  'cyan',
];

// Reference chart: https://en.m.wikipedia.org/wiki/ANSI_escape_code#Colors
const asciColorMap: any = {
  black: 30,
  red: 31,
  green: 32,
  yellow: 33,
  blue: 34,
  magenta: 35,
  cyan: 36,
  darkgray: 90,
};

const getColorString = (messageType: string) => {
  const colorName = colorMap[messageType] || '';
  if (isBrowser) {
    const props = [ `color: ${colorName}` ];
    if (lightMode && clashWithLightMode.indexOf(colorName) !== -1) {
      props.push('background-color: gray');
    }
    return props.join('; ');
  } else if (!isDeployedToAWS && asciColorMap[colorName]) {
    return `[${asciColorMap[colorName]}m`;
  }
  return '';
};

const logWithColor = (callerMsg: string, messageType: string, args: any[] = []) => {
  const colorStr = getColorString(messageType) || '';

  const finalArgs = [];
  if (colorStr === '') {
    finalArgs.push( callerMsg );
  } else if (typeof window !== 'undefined') {
    finalArgs.push( `%c${callerMsg}`, colorStr );
  } else {
    finalArgs.push( `\x1b${colorStr}${callerMsg}\x1b[0m` );
  }

  if (messageType === 'table') {
    console.log(...finalArgs, 'output follows:');
    console.table(...args);
  } else {
    finalArgs.push(...args);
    (console as any)[messageType](...finalArgs);
  }
};

export {
  logWithColor,
};
