/* eslint-disable comma-dangle, quotes */

// !!! WARNING !!! This file should only be updated with the update script via: `npm run update-theme-default`.

import { PbsKidsTheme } from '@/types/pbskids-graph';

export const defaultTheme: PbsKidsTheme = {
  "__typename": "PbsKidsTheme",
  "id": "-1",
  "slug": "default-hardcoded-web-theme",
  "title": "(Default Hardcoded Web Theme)",
  "bodyTextColor": "#000000",
  "bodyBackgroundColor": "#f7e03b",
  "accentBackgroundColor": null,
  "backgroundImage": [],
  "backgroundPattern": [],
  "featureBackgroundColor": "#ffffff",
  "featureHighlightColor": "#e4276a",
  "calloutTextColor": "#ffffff",
  "calloutBackgroundColor": "#e4276a",
  "badgeTextColor": "#ffffff",
  "badgeBackgroundColor": "#08aa4d",
  "uiControlColor": "#a220df",
  "uiLabelColor": "#ffffff",
  "uiHighlightColor": "#bc0159",
  "sponsorLogoStyle": "dark",
  "stationLogoStyle": "color"
};
