import { PbsKidsTheme } from '@/types/pbskids-graph';

export type Station = {
  callSign: string;
  commonName: string;
  logos: {
    color: string | null;
    white: string | null;
  };
  siteUrl: string | null;
  timezone: string | null;
  tvssUrl: string | null;
  zipCode: string | null;
  theme: Array<PbsKidsTheme>;
};

export enum StationLogoTheme {
  Color = 'color',
  White = 'white',
}
