import Head from 'next/head';
import Script from 'next/script';

export default function TrackingIncludes() {
  return <>
    {/* Start Google Tag Manager */}
    <Head>
      <link rel='preconnect' href='//www.googletagmanager.com' crossOrigin='anonymous' />
    </Head>
    <noscript>
      <iframe src='//www.googletagmanager.com/ns.html?id=GTM-MR8JHB' height='0' width='0' style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
    <Script id='google-tag-manager'>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MR8JHB');
      `}
    </Script>
    {/* End Google Tag Manager */}
  </>;
}
